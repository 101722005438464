import { InjectedConnector } from '@web3-react/injected-connector'


export const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 10, 11, 42, 15, 97, 56, 5555],
})

export const INIT_CODE_HASH = '0xa545d296122fc2363a9847cf3d9c61acab5eeb250fa8ac67ef47db80431612bd';

export const enum SUPPORT_CHAINIDS {
    MAINNET = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GOERLI = 5,
    KOVAN = 42,
    DEV = 15,
    BSC_TEST = 97,
    BSC_MAINNET = 56,
    STAKE = 5555,
};

export interface ITokenData {
    token: string,
    amount: string,
    amountView: string,
}
export const MAX_UNIT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const pairData = ["0x796acbA6556f70A3c5756A0d8Fd0a10251c21050"]
// export const pairData = []
// export const tokenData=["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c","0x55d398326f99059fF775485246999027B3197955","0xc9882def23bc42d53895b8361d0b1edc7570bc6a"]
export const tokenData = [
    "BNB",
    "0x55d398326f99059fF775485246999027B3197955",
    "0xc9882def23bc42d53895b8361d0b1edc7570bc6a",
    // "0x3e23aa1F41EB9F8C0832c23a1C5E05F73D3350D5",
    "0x04fA9Eb295266d9d4650EDCB879da204887Dc3Da",
    "0x87d8e64515a7d058fa15c32c938f7379db926306",
    "0xf0d65ce6f671813b1368a7d886f6764886a42ede",
    "0x12a055d95855b4ec2cd70c1a5eadb1ed43eaef65",
    // "0x493BB0F1667E3d06b229ebd8aad33ab246aA3E5F",
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3"]

// "0x13534d78B9B547DD501557e8f108a6e5d13A7704", 


export const light = {
    '@--bg': "#f7f8fa",
    '@--bg1': "#ffffff",
    '@--bg2': "#ced0d9",
    '@--textColor': "#565a69",
    '@--borderColor': "#edeef2",
    '@--blackOrwhite': "#000",
    '@--whiteOrblack': "#fff",
    '@--setbg': "#f7f8fa",
    '@--bgmanger': "#f7f8fa",
}

export const dark = {
    '@--bg': "#21252a",
    '@--bg1': "#191b1f",
    '@--bg2': "#40444f",
    '@--textColor': "#c3c5cb",
    '@--borderColor': "#2c2e36",
    '@--blackOrwhite': "#fff",
    '@--whiteOrblack': "#000",
    '@--setbg': "#191b1f",
    '@--bgmanger': "#212429",
}