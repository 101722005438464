import { useEffect, useState } from "react";
import TokenCyPop from "../../../components/pop/tokenCyPop";
import { useNavigate } from "react-router-dom";
import FooterNav from "../../../components/footerNav/footerNav";
import TokenBalance from "../../../components/token/tokenBalance";
import TokenName from "../../../components/token/tokenName";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { getProviderOrSigner, useFistFactoryContract } from "../../../hooks/useContract";
import { Contract } from "ethers";
import PairABI from '../../../abis/Pair.json';
import TokenAandTokenB from "../../../components/token/tokenAandTokenB";
import './addPair.css';
import { pairData } from "../../../constants";
import { fromTokenValue, removeDup } from "../../../utils";
import { AddressZero } from '@ethersproject/constants'
import { useTranslation } from "react-i18next";
import ERC20ABI from '../../../abis/erc20.json';
import ChangeLanguage from "../../../components/changeLanguage/changeLanguage";

const FIST = process.env.REACT_APP_TOKEN_FIST + "";
const USDT = process.env.REACT_APP_TOKEN_USDT + "";
function AddPair() {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const fistFactoryContract = useFistFactoryContract();
    const { account, library } = useWeb3React()

    const [tokenA, setTokenA] = useState<string>(FIST);
    const [tokenB, setTokenB] = useState<string>(USDT);

    const [tokenPopOpen, setTpkenPopOpen] = useState<boolean>(false);
    const [tokenPopOpenType, setTpkenPopOpenType] = useState<string>("in");

    const [accountPairAmount, setAccountPairAmount] = useState<string>("0")
    const [pairTotal, setPairTotal] = useState<string>("0")
    const [pairAddr, setPairAddr] = useState<string>("")
    const [pairList, setPairList] = useState<any>([])
    const [addPair, setAddpair] = useState<boolean>(false);

    const [tokenAReserves, setTokenAReserves] = useState<string>("0")
    const [tokenBReserves, setTokenBReserves] = useState<string>("0")

    const [tokenADecimals, setTokenADecimals] = useState<string>("0")
    const [tokenBDecimals, setTokenBDecimals] = useState<string>("0")
    useEffect(() => {
        setAddpair(false)
        getPair(tokenA, tokenB)
        getAmountsOut(tokenA, tokenB)
    }, [tokenA, tokenB, account])

    const getAmountsOut = async (tokena: string, tokenb: string) => {
        if (tokena == "BNB") {
            tokena = process.env.REACT_APP_TOKEN_BNB + ""
        }

        if (tokenb == "BNB") {
            tokenb = process.env.REACT_APP_TOKEN_BNB + ""
        }
        try {
            let tokenAErc20 = new Contract(tokena, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            let tokenBErc20 = new Contract(tokenb, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            const tokenADataDecimals: any = await tokenAErc20?.decimals()
            setTokenADecimals(tokenADataDecimals)
            const tokenBDataDecimals: any = await tokenBErc20?.decimals()
            setTokenBDecimals(tokenBDataDecimals)

        } catch (error) {
        }
    }

    const getPair = async (tokena: string, tokenb: string) => {
        console.log(12)

        if (tokena == "BNB") {
            tokena = process.env.REACT_APP_TOKEN_BNB + ""
        }

        if (tokenb == "BNB") {
            tokenb = process.env.REACT_APP_TOKEN_BNB + ""
        }

        let localPairData = localStorage.getItem("localPairData") + "";

        let arr = pairData

        let dataArr: any = []

        if (localPairData) {
            var newArr = removeDup(arr.concat(JSON.parse(localPairData)));
            dataArr = newArr;
        } else {
            dataArr = arr
        }
        setPairList(dataArr)

        try {
            let date = await fistFactoryContract?.getPair(tokena, tokenb)
            console.log("getPair", date, date !== AddressZero)

            setPairAddr(date)
            if (date == AddressZero) {
                return
            }

            let dataIndex = dataArr.findIndex((itemData: any) => {
                return itemData == date
            });

            if (dataIndex == -1) {
                setAddpair(true)
            } else {
                setAddpair(false)
            }

            let pairContract = new Contract(date, PairABI, getProviderOrSigner(library, account || "") as any);

            let pairBalance = await pairContract?.balanceOf(account)
            setAccountPairAmount(pairBalance.toString())

            let total = await pairContract?.totalSupply()
            setPairTotal(total.toString())

            let token0 = await pairContract?.token0()
            console.log("token0", token0)

            let reserves = await pairContract?.getReserves()
            console.log("getReserves", reserves, reserves._reserve0.toString(), reserves._reserve1.toString())

            if (tokena == token0) {
                setTokenAReserves(reserves._reserve0.toString());
                setTokenBReserves(reserves._reserve1.toString());
            } else {
                setTokenAReserves(reserves._reserve1.toString());
                setTokenBReserves(reserves._reserve0.toString());
            }

        } catch (error) {
            setPairAddr("")
            setAccountPairAmount("0");
            setPairTotal("0");
            setTokenAReserves("0");
            setTokenBReserves("0");
        }
    }

    const setPairData = () => {
        let data: any = pairList
        data.push(pairAddr)
        localStorage.setItem("localPairData", JSON.stringify(removeDup(data)));
        navigate('/pool')
    }

    return (
        <>
            <TokenCyPop open={tokenPopOpen} setOpen={setTpkenPopOpen} tokenType={tokenPopOpenType} setTokenA={setTokenA} setTokenB={setTokenB} />
            <div className='mainContent'>
                <div className='bg1 poolAddBox '>
                    <div className="poolheader flex">
                        <div onClick={() => {
                            navigate('/pool')
                        }}>
                            <svg className="sc-1tguxka-4 kSTWMO blackOrwhite" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                        </div>
                        <div className="flex-1">
                            <p className=" fontw500 font18 textCenter blackOrwhite" >{t("ImportPool")}</p>
                        </div>
                    </div>

                    <div className="messageInfo">
                        <p className="mainColor font16">{t("ImportPool1")}</p>
                    </div>

                    <div className="flex addPairBox borderColor textColor" onClick={() => {
                        setTpkenPopOpen(true)
                        setTpkenPopOpenType("in")
                    }}>
                        <div className="flex-1 fontw500 font18">
                            <TokenName token={tokenA} />
                        </div>
                        <div>
                            <svg className="sc-9ag4mq-8 euovgw blackOrwhite" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M0.97168 1L6.20532 6L11.439 1" stroke="currentColor"></path></svg>
                        </div>
                    </div>

                    <div className="textCenter textColor">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>
                    <div className="flex addPairBox borderColor textColor" onClick={() => {
                        setTpkenPopOpen(true)
                        setTpkenPopOpenType("out")
                    }}>
                        <div className="flex-1 fontw500 font18">
                            <TokenName token={tokenB} />
                        </div>
                        <div>
                            <svg className="sc-9ag4mq-8 euovgw blackOrwhite" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M0.97168 1L6.20532 6L11.439 1" stroke="currentColor"></path></svg>
                        </div>
                    </div>
                    {
                        addPair ? <div className="sendBtn" >
                            <p onClick={() => {
                                setPairData()
                            }}> {t("Import")} </p>
                        </div> : <div className="textColor textCenter">
                            {
                                pairAddr !== AddressZero ? <div>
                                    <p>{t("PoolFound")}</p>
                                    <p style={{
                                        color: "#f3b71e"
                                    }}>{t("ManageThisPool")}</p>
                                </div> : <>{t("NoPoolFound")}</>
                            }
                        </div>
                    }
                </div>

                <div style={{
                    paddingTop: "20px"
                }}>
                    <ChangeLanguage />
                </div>
                {
                    pairAddr !== AddressZero ? <div className="poolDetailBox bg2">
                        <div>
                            <p className="blackOrwhite font16"> {t("YourPosition")}</p>
                        </div>
                        <div className="flex blackOrwhite " style={{
                            padding: "8px 0px"
                        }}>
                            <div className="flex-1">
                                <div className="flex font18">
                                    <TokenAandTokenB tokenA={tokenA} tokenB={tokenB} />
                                    <TokenName token={tokenA} />
                                    /
                                    <TokenName token={tokenB} />
                                </div>

                            </div>
                            <div className="font18"><TokenBalance token={pairAddr} addr={account + ""} decimalPlaces={6} /></div>
                        </div>
                        <div className="flex blackOrwhite font16">
                            <div className="flex-1">{t("YourPoolShare")}:</div>
                            <div>
                                {accountPairAmount == "0" ? 0 : <>
                                    {
                                        new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01) ? "<0.01" : new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01)
                                    }
                                </>}%
                            </div>
                        </div>
                        <div className="flex blackOrwhite font16">
                            <div className="flex-1"><TokenName token={tokenA} />:</div>
                            <div>
                                {
                                    fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenADecimals), 3)
                                }
                            </div>
                        </div>
                        <div className="flex blackOrwhite font16">
                            <div className="flex-1"><TokenName token={tokenB} />:</div>
                            <div>
                                {
                                    fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenBDecimals), 3)
                                }
                            </div>
                        </div>
                    </div> : <></>
                }
            </div>
            <div className="footer bg1 borderColor ">
                <FooterNav />
            </div>
        </>
    )
}

export default AddPair