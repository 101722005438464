import { Modal, Switch } from 'antd'
import { useEffect, useState } from 'react'
import TokenName from '../token/tokenName'
import TokenBalance from '../token/tokenBalance'
import { useWeb3React } from '@web3-react/core'
import { ITokenData, tokenData } from '../../constants'
import { removeDup } from '../../utils'
import { isAddress } from 'ethers/lib/utils'
import { useNavigate } from 'react-router-dom'
import TokenIcon from '../token/tokenIcon'
import { useTranslation } from 'react-i18next'
import { notFund } from '../../image'
interface OpenStatus {
    open: boolean,
    setOpen: Function,
    tokenType: string,
    tokenIn?: ITokenData,
    setTokenIn?: Function,
    tokenOut?: ITokenData,
    setTokenOut?: Function,
    linkType?: string,
    linkTokenA?: string,
    linkTokenB?: string,
    setTokenA?: Function,
    setTokenB?: Function,
}

function TokenCyPop({ open, setOpen, tokenType, tokenIn, setTokenIn, tokenOut, setTokenOut, linkType, linkTokenA, linkTokenB, setTokenA, setTokenB }: OpenStatus) {
    const { account } = useWeb3React()
    const navigate = useNavigate();
    const { t } = useTranslation()


    const [type, setType] = useState<boolean>(false);
    const [typeManger, setTypeManger] = useState<boolean>(true);
    const [localData, setLocalData] = useState<any>([]);
    const [tokenList, setTopkenList] = useState<any>([]);
    const [inputValue, setInputValu] = useState<string>("")
    const [isToken, setIsToken] = useState<boolean>(false);
    const [isSwitch, setIsSwitch] = useState<boolean>(false);

    const [headData, setHeadData] = useState<any>(["0xC9882dEF23bc42D53895b8361D0b1EDC7570Bc6A", "0x55d398326f99059fF775485246999027B3197955", "0x04fA9Eb295266d9d4650EDCB879da204887Dc3Da", "BNB"])

    useEffect(() => {
        getPaird()
    }, [account, isSwitch])

    const handleCy = (item: any) => {
        if (tokenOut && setTokenOut && setTokenIn && tokenIn) {
            let obj: ITokenData = {
                amount: "",
                token: "",
                amountView: ""
            };
            obj.token = item
            if (tokenType == "in") {
                tokenOut.amount = "";
                tokenOut.amountView = "";
                setTokenOut({ ...tokenOut });
                setTokenIn(obj)
            } else if (tokenType == "out") {
                setTokenOut(obj)
                tokenIn.amount = "";
                tokenIn.amountView = "";
                setTokenIn({ ...tokenIn });
            }
        } else if (linkTokenA && linkTokenB && linkType) {
            if (tokenType == "in") {
                navigate('/pool/add/' + item + '/' + linkTokenB)
            } else if (tokenType == "out") {
                navigate('/pool/add/' + linkTokenA + '/' + item)
            }
        } else if (setTokenA && setTokenB) {
            if (tokenType == "in") {
                setTokenA(item)
            } else if (tokenType == "out") {
                setTokenB(item)
            }
        }
        setOpen(false)
    }

    const getPaird = async () => {
        let localPairData = localStorage.getItem("localTokenData") + "";
        let arr = tokenData
        setTopkenList(arr)
        if (!isSwitch) {
            if (localPairData) {
                if (JSON.parse(localPairData) !== null) {
                    setLocalData(JSON.parse(localPairData))
                    var newArr = removeDup(arr.concat(JSON.parse(localPairData)));
                    console.log(newArr)
                    setTopkenList(newArr)
                } else {
                    setLocalData([])
                    setTopkenList(arr)
                }
            } else {
                setTopkenList(arr)
            }
        } else {
            setTopkenList(arr)
        }

        setInputValu("")
        setIsToken(false)
    }

    const tokenInfo = async (token: string) => {
        console.log(token, isAddress(token))
        setIsToken(isAddress(token))
        setInputValu(token)
    }

    const setLocal = () => {
        let data: any = []
        let localPairData = JSON.parse(localStorage.getItem("localTokenData") + "");
        if (localPairData != null && localPairData != undefined) {
            let dataIndex = localPairData.findIndex((itemData: any) => {
                return itemData.tabPos == inputValue
            });
            if (dataIndex == -1) {
                localPairData.push(inputValue)
                localStorage.setItem("localTokenData", JSON.stringify(removeDup(localPairData)));
                getPaird()
            }
        } else {
            data.push(inputValue)
            localStorage.setItem("localTokenData", JSON.stringify(data));
            getPaird()
        }
    }

    const selectTokenandLocal = () => {
        handleCy(inputValue)
        setLocal()
    }

    const deleteLocal = (index: number) => {
        console.log(index)
        let localPairData = JSON.parse(localStorage.getItem("localTokenData") + "");
        let data = localPairData.splice(index, 1)
        localStorage.setItem("localTokenData", JSON.stringify(localPairData));
        getPaird()
    }

    return (
        <Modal
            className='tokenCyPop'
            open={open}
            centered
            bodyStyle={{
                borderRadius: "16px"
            }}
            footer={null}
            closeIcon={null}
        >
            <div className='bg' style={{
                borderTopRightRadius: "16px",
                borderTopLeftRadius: "16px",
                padding: "20px 20px 8px 20px"
            }}>
                {
                    !type ? <div className='flex blackOrwhite'>
                        <div className="flex-1 font16 fontw500">{t("Selectatoken")}</div>
                        <div onClick={() => {
                            setOpen(false)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </div> : <div className='flex blackOrwhite '>
                        <div onClick={() => {
                            setType(!type)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style={{
                                cursor: "pointer"
                            }}><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                        </div>
                        <div className="flex-1 font16 fontw500 textCenter">{t("Manage")}</div>
                        <div onClick={() => {
                            setOpen(false)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </div>
                }
                {
                    !type ? <></> : <div className='borderColor' style={{
                        marginTop: "10px"
                    }}>
                        <div className='flex blackOrwhite bg2 tokenMangerBox'>
                            <div className="flex-1">
                                <span onClick={() => {
                                    setTypeManger(true)
                                }} className={typeManger ? 'tokenManger bgmanger' : 'tokenManger'}>{t("Lists")}</span>
                            </div>
                            <div className='flex-1'>
                                <span onClick={() => {
                                    setTypeManger(false)
                                }} className={!typeManger ? 'tokenManger bgmanger' : 'tokenManger'}>{t("Tokens")}</span>
                            </div>
                        </div>
                    </div>
                }
                <div>
                    <input className='searchtoken bg borderColor' placeholder={`${t("Searchpasteaddress")}`} value={inputValue} type="text" onChange={(e) => {
                        tokenInfo(e.target.value)
                    }} />
                </div>
                {
                    isToken && <>
                        {
                            !type ? <>
                                <div className='flex tokenDao'>
                                    <div className='flex-1 '><TokenName token={inputValue} />  </div>
                                    <div className='tokenDaoBtn'>
                                        <span onClick={() => {
                                            selectTokenandLocal()
                                        }}>{t("Import")}</span>
                                    </div>
                                </div>

                            </> : <>
                                <div className='flex bg2 tokenDao'>
                                    <div className='flex-1 '><TokenName token={inputValue} />  </div>
                                    <div className='tokenDaoBtn'>
                                        <span onClick={() => {
                                            setLocal()
                                        }}>{t("Import")}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }


                {
                    !type ? <div className='flex'>
                        {
                            headData.map((item: any) => {
                                return <div className="flex-1 ">
                                    <div className='borderColor blackOrwhite' style={{
                                        border: "1px solid",
                                        margin: "0px 2px",
                                        borderRadius: "10px",
                                        padding: "2px ",
                                        cursor: "pointer"
                                    }} onClick={() => {
                                        handleCy(item)
                                    }}>
                                        <TokenIcon token={item} /><span style={{
                                            position: "relative",
                                            top: "2px",
                                            margin: "2px 0px 0px 3px"
                                        }}><TokenName token={item} /></span>
                                    </div>

                                </div>
                            })
                        }
                    </div> : <></>
                }

            </div>

            <div className='borderColor bg' style={{
                maxHeight: "35vh",
                minHeight: "35vh",
                width: "100%",
                overflowY: 'scroll',
                borderTop: "1px solid ",
                borderBottom: "1px solid",
            }}>
                {
                    !type ? <>{
                        tokenList.map((item: any, index: number) => {
                            return <div key={index} className='flex font16 blackOrwhite' style={{
                                padding: "6px 16px 6px",
                                margin: "12px 0px"
                            }} onClick={() => {
                                handleCy(item)
                            }}>
                                <div className='flex-1 '>
                                    <TokenIcon token={item} />
                                    <span style={{
                                        position: "relative",
                                        top: "2px",
                                        margin: "2px 5px 0px 10px "
                                    }}>
                                        <TokenName token={item} />
                                    </span>
                                </div>
                                <div>
                                    <TokenBalance token={item} addr={account + ""} decimalPlaces={4} />
                                </div>
                            </div>
                        })
                    }</> : <>
                        {
                            typeManger ? <div style={{
                                margin: "16px",
                            }}>
                                <div className={!isSwitch ? 'flex fstBox switchbg' : 'flex fstBox bg2'} style={{
                                    padding: "16px",
                                    borderRadius: "16px"
                                }}>
                                    <div className='flex-1 flex'>
                                        <div>
                                            <img style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "24px",
                                                marginTop: "5px",
                                                marginRight: "10px"
                                            }} src={notFund} alt="" />
                                        </div>

                                        <div className={!isSwitch ? ' whiteColor' : ' textColor'}  >
                                            <p >FSTSWAP</p>
                                            <p style={{
                                                fontSize: "12px"
                                            }}>
                                                {localData.length} {t("Tokens")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='switch bgmanger'>
                                            {isSwitch ? <div className='flex'>
                                                <span className='switchbtn2' onClick={() => {
                                                    setIsSwitch(false)
                                                }}>&nbsp;</span>
                                                <span> {t("OFF")}</span>
                                            </div> : <div className='flex'>
                                                <span>{t("ON")}</span>
                                                <span className='switchbtn1' onClick={() => {
                                                    setIsSwitch(true)
                                                }}>&nbsp;</span>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div> : <>
                                <div className='flex' style={{
                                    padding: '16px 16px 0'
                                }}>
                                    <div className="flex-1">{localData.length} {t("CustomTokens")}</div>
                                    <div onClick={() => {
                                        let data: any = []
                                        localStorage.setItem("localTokenData", JSON.stringify(data));
                                        getPaird()
                                    }} style={{
                                        color: "#f3b71e"
                                    }}>
                                        {t("Clearall")}
                                    </div>
                                </div>
                                {
                                    localData.map((item: any, index: number) => {
                                        return <div key={index} className='flex font16 blackOrwhite' style={{
                                            padding: "10px 16px 0px"
                                        }}>
                                            <div className='flex-1 '>
                                                <TokenIcon token={item} />
                                                <span style={{
                                                    position: "relative",
                                                    top: "2px",
                                                    margin: "2px 5px 0px"
                                                }}><TokenName token={item} />
                                                </span>
                                            </div>
                                            <div>
                                                <svg
                                                    onClick={() => {
                                                        deleteLocal(index)
                                                    }}
                                                    style={{
                                                        position: "relative",
                                                        top: "2px",
                                                        right: "10px"
                                                    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-8 kuDLAj"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>

                                                <svg
                                                    onClick={() => {
                                                        window.open("https://bscscan.com/address/" + item, '_blank');
                                                    }}
                                                    style={{
                                                        position: "relative",
                                                        top: "2px",
                                                        color: "#f3b71e",
                                                        cursor: "pointer"
                                                    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-7 dWrzTS"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                            </div>
                                        </div>
                                    })
                                }
                            </>
                        }
                    </>
                }
            </div>

            {
                !type ? <div className='textCenter bg' onClick={() => {
                    setType(!type)
                }} style={{
                    borderBottomRightRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    color: "#f3b71e",
                    padding: "16px 0"
                }}>
                    <svg style={{
                        position: "relative",
                        top: "3px",
                        right: "3px"
                    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    <span >{t("ManageTokenLists")}</span>
                </div> : <div className='textColor bg' style={{
                    borderBottomRightRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    padding: "16px 16px"
                }}>
                    {t("tokendetail1")}
                </div>
            }
        </Modal>
    )
}

export default TokenCyPop