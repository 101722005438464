import { useWeb3React } from "@web3-react/core";
import { Modal, message } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

interface OpenStatus {
    hash: string
}
const ethers = require('ethers');

function TipPop({ hash }: OpenStatus) {
    const [open, setOpen] = useState<boolean>(false);
    const { library } = useWeb3React()
    const { t } = useTranslation();

    useEffect(() => {
        getHash()
    }, [hash])

    const getHash = async () => {
        if (hash) {
            setOpen(true)
            let provider = new ethers.providers.Web3Provider(library.provider);
            let receipt = await provider.waitForTransaction(hash);

            if (receipt !== null) {
                setOpen(false)
                if (receipt.status && receipt.status == 1) {
                    message.success(`${t("successfulTransaction")}`, 3)
                } else {
                    message.error(`${t("transactionFailed")}`, 3)
                }
            }else{
                setOpen(false)
            }
        }else{
            setOpen(false)
        }
    }
    return (<Modal
        className="tipPopHash"
        open={open}
        centered
        footer={null}
        closeIcon={null}
        zIndex={100}
    >
        <div>
            1  {t("pending")}  <svg style={{
                width: "20px",
                height: "20px",
                marginLeft: "5px",
                position: "relative",
                top: "5px"
            }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="white" className="sc-bftyxn-0 ldGXgk rotate"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </div>
    </Modal>)
}

export default TipPop