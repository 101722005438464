import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
const FIST = process.env.REACT_APP_TOKEN_FIST + "";
const USDT = process.env.REACT_APP_TOKEN_USDT + "";
function FooterNav() {

    const navigate = useNavigate();
    const location = useLocation()
    const { t } = useTranslation()

    const [path, setPath] = useState<string>('/')

    useEffect(() => {
        setPath(location.pathname)
    }, [location.pathname])

    const handleClickNav = (url: string) => {
        navigate(url);
    };

    return (
        <div className="footerNav flex" >
            <div className=" footerNavItem flex-1" onClick={() => {
                handleClickNav('/home/' + FIST + '/' + USDT)
            }}>
                <svg className={path == "/" || path.indexOf("home") != -1 ? 'navSelectColor' : 'textColor'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                <p className={path == "/" || path.indexOf("home") != -1 ? 'navSelectColor' : 'textColor'}>{t("Home")}</p>
            </div>
            <div className=" footerNavItem flex-1" onClick={() => {
                handleClickNav('/swap')
            }}>
                <svg className={path.indexOf("swap") != -1 ? 'navSelectColor' : 'textColor'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                <p className={path.indexOf("swap") != -1 ? 'navSelectColor' : 'textColor'}>{t("Swap")}</p>
            </div>
            <div className=" footerNavItem flex-1" onClick={() => {
                handleClickNav('/pool')
            }}>
                <svg className={path.indexOf("pool") != -1 ? 'navSelectColor' : 'textColor'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z"></path><path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path><path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z"></path><path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z"></path><path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z"></path><path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"></path><path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z"></path><path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z"></path></svg>
                <p className={path.indexOf("pool") != -1 ? 'navSelectColor' : 'textColor'} >{t("Pool")}</p>
            </div>
            <div className=" footerNavItem flex-1" onClick={() => {
                handleClickNav('/my')
            }}>
                <svg className={path.indexOf("my") != -1 ? 'navSelectColor' : 'textColor'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <p className={path.indexOf("my") != -1 ? 'navSelectColor' : 'textColor'}> {t("My")}</p>
            </div>
        </div>
    )
}

export default FooterNav