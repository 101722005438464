import { useTranslation } from 'react-i18next'

function RecentTransactionsBoxContent() {
    const { t } = useTranslation()

    return (<div className='recentTransactionsBoxConetent bg1'>
        <div className="datas">
            <div className="datasItem " style={{
                width: "300px",
                marginLeft: "10px"
            }}>Actions</div>
            <div className="datasItem" style={{
                width: "200px"
            }}>Token Amount</div>
            <div className="datasItem" style={{
                width: "200px"
            }}>Token Amount</div>

            <div className="datasItem" style={{
                width: "200px"
            }}>Account</div>

            <div className="datasItem" style={{
                width: "200px"
            }}>Time</div>
        </div>

        <div style={{
            height: "80px",
            lineHeight: "60px",
            padding: "10px 0 10px 20px"
        }}>
            <p className='blackOrwhite'> {t("RecentTransactionsDetail")}</p>
        </div>
    </div>)
}

export default RecentTransactionsBoxContent