import { useEffect, useState } from "react"
import FooterNav from "../../components/footerNav/footerNav"
import TokenAandTokenB from "../../components/token/tokenAandTokenB"
import TokenName from "../../components/token/tokenName"
import './home.css';
import { useParams } from "react-router-dom";
import { getProviderOrSigner, useFistFactoryContract, useFistRouterContract } from "../../hooks/useContract";
import TokenBalance from "../../components/token/tokenBalance";
import { useWeb3React } from "@web3-react/core";
import { fromTokenValue, toTokenValue, trimNumber } from "../../utils";
import BigNumber from "bignumber.js";
import ERC20ABI from '../../abis/erc20.json';
import PairABI from '../../abis/Pair.json';
import { Contract } from "@ethersproject/contracts";
import { useTranslation } from "react-i18next";
import RecentTransactionsBoxContent from "../recentTransactions/recentTransactionsBoxContent";



const FIST = process.env.REACT_APP_TOKEN_FIST + "";
const USDT = process.env.REACT_APP_TOKEN_USDT + "";

function Home() {
    const fistFactoryContract = useFistFactoryContract();
    const { account, library } = useWeb3React()
    const routerContract = useFistRouterContract();
    const { t } = useTranslation()


    const params = useParams();
    const [tokenA, setTokenA] = useState<string>(params.token0 + "");
    const [tokenB, setTokenB] = useState<string>(params.token1 + "");

    const [tokenABalance, setTokenABalance] = useState<string>("0");
    const [tokenBBalance, setTokenBBalance] = useState<string>("0");

    const [tokenAtoTokenB, setTokenAtoTokenB] = useState<string>("0")

    const [tokenADecimals, setTokenADecimals] = useState<string>("0")
    const [tokenBDecimals, setTokenBDecimals] = useState<string>("0")

    const [accountPairAmount, setAccountPairAmount] = useState<string>("0")
    const [pairTotal, setPairTotal] = useState<string>("0")

    const [tokenAReserves, setTokenAReserves] = useState<string>("0")
    const [tokenBReserves, setTokenBReserves] = useState<string>("0")

    const [selectItem, setSelectItem] = useState<boolean>(false)

    const [k, setK] = useState<number>(1);

    useEffect(() => {
        init()
    }, [params, account]);

    const init = () => {
        let tokena = params.tokenA || FIST
        let tokenb = params.tokenB || USDT

        if (tokena == "BNB") {
            tokena = process.env.REACT_APP_TOKEN_BNB + ""
        }

        if (tokenb == "BNB") {
            tokenb = process.env.REACT_APP_TOKEN_BNB + ""
        }

        setTokenA(tokena);
        setTokenB(tokenb);
        getAmountsOut(tokena, tokenb)
        getPair(tokena, tokenb)
    }

    const getAmountsOut = async (tokena: string, tokenb: string) => {
        try {
            let tokenAErc20 = new Contract(tokena, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            let tokenBErc20 = new Contract(tokenb, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            const tokenADataDecimals: any = await tokenAErc20?.decimals()
            setTokenADecimals(tokenADataDecimals)
            const tokenBDataDecimals: any = await tokenBErc20?.decimals()
            setTokenBDecimals(tokenBDataDecimals)
            let info = await routerContract?.getAmountsOut(toTokenValue(1, tokenADataDecimals), [tokena, tokenb])
            console.log("info", info[0].toString(), info[1].toString())
            setTokenAtoTokenB(info[1].toString())
        } catch (error) {
            setTokenAtoTokenB("0")
        }
    }

    const getPair = async (tokena: string, tokenb: string) => {

        try {
            let date = await fistFactoryContract?.getPair(tokena, tokenb)
            console.log("getPair", date)
            let pairContract = new Contract(date, PairABI, getProviderOrSigner(library, account || "") as any);

            let pairBalance = await pairContract?.balanceOf(account)
            setAccountPairAmount(pairBalance.toString())

            let total = await pairContract?.totalSupply()
            setPairTotal(total.toString())

            let token0 = await pairContract?.token0()
            console.log("token0", token0)

            let reserves = await pairContract?.getReserves()
            console.log("getReserves", reserves)

            if (tokena == token0) {
                setTokenAReserves(reserves._reserve0.toString());
                setTokenBReserves(reserves._reserve1.toString());
            } else {
                setTokenAReserves(reserves._reserve1.toString());
                setTokenBReserves(reserves._reserve0.toString());
            }

        } catch (error) {
            setAccountPairAmount("0");
            setPairTotal("0");
            setTokenAReserves("0");
            setTokenBReserves("0");
        }
    }

    return (
        <>
            <div className='mainContent home'>
                <div className="flex bg1 header borderColor">
                    <TokenAandTokenB tokenA={tokenA} tokenB={tokenB} />
                    <p className="mainColor font16" >
                        <TokenName token={tokenA} />
                        <span>-</span>
                        <TokenName token={tokenB} />
                    </p>
                </div>
                <div className="bg1 mainColor account">
                    <p className="font16">{t("YourAccountBalance")}</p>
                </div>

                <div className="flex accountBalance borderColor bg1">
                    <div className="borderColor accountBalanceItem flex-1 " style={{
                        borderRight: "1px solid",
                    }} >
                        <p className="textColor">
                            <TokenName token={tokenA} />
                        </p>
                        <p className="blackOrwhite fontw500 font18">
                            <TokenBalance token={tokenA} addr={account + ""} decimalPlaces={3} setTokenBalance={setTokenABalance} />
                        </p>
                    </div>
                    <div className=" flex-1 accountBalanceItem">
                        <p className="textColor">
                            <TokenName token={tokenB} />
                        </p>
                        <p className="blackOrwhite fontw500 font18">
                            <TokenBalance token={tokenB} addr={account + ""} decimalPlaces={3} setTokenBalance={setTokenBBalance} />
                        </p>
                    </div>
                </div>

                <div className="bg1 homeItem">
                    <p className="textColor">
                        {t("YourTotalAssets")}:
                    </p>
                    <p className="blackOrwhite fontw500 font18">
                        ≈ {
                            trimNumber(new BigNumber(fromTokenValue(tokenABalance, Number(tokenADecimals))).plus(new BigNumber(tokenBBalance).dividedBy(tokenAtoTokenB).toString()).toFixed(3, 1), 3)
                        } <TokenName token={tokenA} />
                    </p>
                </div>

                <div className="bg1 homeItem">
                    <p className="textColor">
                        {t("CurrencyPrice")}:
                    </p>
                    <p className="blackOrwhite fontw500 font18">
                        1 <TokenName token={tokenA} />  ≈ {fromTokenValue(tokenAtoTokenB, Number(tokenBDecimals), 3)} <TokenName token={tokenB} />
                    </p>
                </div>

                <div className="bg1 mainColor account">
                    <p className="font16">{t("YourTotalPool")}</p>
                </div>

                <div className="flex accountBalance borderColor bg1">
                    <div className="borderColor accountBalanceItem flex-1 " style={{
                        borderRight: "1px solid",
                    }} >
                        <div>
                            <p className="textColor">
                                <TokenName token={tokenA} />
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                {
                                    fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenADecimals), 3)
                                }
                            </p>
                        </div>

                        <div style={{
                            padding: "12px 0"
                        }}>
                            <p className="textColor">
                                {t("EstimateFee(24hr)")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>

                    </div>
                    <div className=" flex-1 accountBalanceItem">
                        <div>
                            <p className="textColor">
                                <TokenName token={tokenB} />
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                {
                                    fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenBDecimals), 3)
                                }
                            </p>
                        </div>
                        <div style={{
                            padding: "12px 0"
                        }}>
                            <p className="textColor">
                                {t("EstimateFee(24hr)")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg1 homeItem">
                    <p className="textColor">
                        {t("YourPoolShare")}:
                    </p>
                    <p className="blackOrwhite fontw500 font18">
                        {accountPairAmount == "0" ? 0 : new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).toFixed(6)}%
                    </p>
                </div>

                <div className="bg1 mainColor account">
                    <p className="font16">{t("TotalPool")}</p>
                </div>
                <div className="flex accountBalance borderColor bg1">
                    <div className="borderColor accountBalanceItem flex-1 " style={{
                        borderRight: "1px solid",
                    }} >
                        <div>
                            <p className="textColor">
                                <TokenName token={tokenA} />
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                {
                                    fromTokenValue(tokenAReserves, Number(tokenADecimals), 3)
                                }
                            </p>
                        </div>
                        <div style={{
                            paddingTop: "12px"
                        }}>
                            <p className="textColor">
                                {t("LPFee")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>
                        <div style={{
                            paddingTop: "12px"
                        }}>
                            <p className="textColor">
                                {t("Volume(24hr)")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>

                        <div style={{
                            padding: "12px 0"
                        }}>
                            <p className="textColor">
                                {t("EstimateFee(24hr)")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>

                    </div>
                    <div className=" flex-1 accountBalanceItem">

                        <div>
                            <p className="textColor">
                                <TokenName token={tokenB} />
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                {
                                    fromTokenValue(tokenBReserves, Number(tokenBDecimals), 3)
                                }
                            </p>
                        </div>
                        <div style={{
                            paddingTop: "12px"
                        }}>
                            <p className="textColor">
                                {t("LPFee")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>
                        <div style={{
                            paddingTop: "12px"
                        }}>
                            <p className="textColor">
                                {t("Volume(24hr)")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>

                        <div style={{
                            padding: "12px 0"
                        }}>
                            <p className="textColor">
                                {t("EstimateFee(24hr)")}
                            </p>
                            <p className="blackOrwhite fontw500 font18">
                                0.0000
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex bg1 borderColor" style={{
                    padding: "10px 0px",
                    borderBottom: "1px solid"
                }}>
                    <div onClick={() => { setSelectItem(!selectItem) }} className={selectItem ? "selectKBox" : "selectKBoxIn"} >K {t("line")}</div>
                    <div onClick={() => { setSelectItem(!selectItem) }} className={selectItem ? "selectKBoxIn" : "selectKBox"} >{t("RecentTransactions")}</div>
                </div>
                <div style={{ paddingBottom: "30px" }}>
                    {
                        selectItem ? <RecentTransactionsBoxContent /> : <div className="bg2" style={{
                            paddingBottom: "20px"
                        }}>
                            <p >
                                <span className={k == 1 ? "kSelect" : "textColor"} onClick={() => { setK(1) }} style={{ paddingRight: "10px " }}>1m</span>
                                <span className={k == 3 ? "kSelect" : "textColor"} onClick={() => { setK(3) }} style={{ paddingRight: "10px " }}>3m</span>
                                <span className={k == 5 ? "kSelect" : "textColor"} onClick={() => { setK(5) }} style={{ paddingRight: "10px " }}>5m</span>
                                <span className={k == 15 ? "kSelect" : "textColor"} onClick={() => { setK(15) }} style={{ paddingRight: "10px " }}>15m</span>
                                <span className={k == 30 ? "kSelect" : "textColor"} onClick={() => { setK(30) }} style={{ paddingRight: "10px " }}>30m</span>
                                <span className={k == 60 ? "kSelect" : "textColor"} onClick={() => { setK(60) }} style={{ paddingRight: "10px " }}>1h</span>
                                <span className={k == 240 ? "kSelect" : "textColor"} onClick={() => { setK(240) }} style={{ paddingRight: "10px " }}>4h</span>
                                <span className={k == 720 ? "kSelect" : "textColor"} onClick={() => { setK(720) }} style={{ paddingRight: "10px " }}>12h</span>
                            </p>
                            <div className="borderColor" style={{
                                height: "270px",
                                borderBottom: "1px solid"
                            }}>

                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="footer bg1 borderColor ">
                <FooterNav />
            </div>
        </>
    )
}

export default Home


