import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { useEffect, useState } from "react"
import PairABI from '../../abis/Pair.json';
import ERC20ABI from '../../abis/erc20.json';
import { getProviderOrSigner } from "../../hooks/useContract";
import BigNumber from "bignumber.js";
import TokenAandTokenB from "../../components/token/tokenAandTokenB";
import TokenName from "../../components/token/tokenName";
import { fromTokenValue, trimNumber } from "../../utils";
import { useNavigate } from "react-router-dom";
import TokenIcon from "../../components/token/tokenIcon";
import { useTranslation } from "react-i18next";

interface IPairItem {
    pairaddr: string
}

function PairItem({ pairaddr }: IPairItem) {
    const { account, library } = useWeb3React()
    const navigate = useNavigate();
    const { t } = useTranslation()

    const [tokenA, setTokenA] = useState<string>("");
    const [tokenB, setTokenB] = useState<string>("");

    const [accountPairAmount, setAccountPairAmount] = useState<string>("0")
    const [pairTotal, setPairTotal] = useState<string>("0")

    const [tokenAReserves, setTokenAReserves] = useState<string>("0")
    const [tokenBReserves, setTokenBReserves] = useState<string>("0")

    const [tokenADecimals, setTokenADecimals] = useState<string>("0")
    const [tokenBDecimals, setTokenBDecimals] = useState<string>("0")

    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        console.log("PairItem", pairaddr)
        getPair(pairaddr)
    }, [])

    const getPair = async (pairaddr: string) => {
        try {

            let pairContract = new Contract(pairaddr, PairABI, getProviderOrSigner(library, account || "") as any);

            let pairBalance = await pairContract?.balanceOf(account)
            console.log("pairBalance", pairaddr, pairBalance.toString())
            setAccountPairAmount(pairBalance.toString())

            let total = await pairContract?.totalSupply()
            setPairTotal(total.toString())

            let token0 = await pairContract?.token0()
            setTokenA(token0)

            console.log("token0", token0)
            let token1 = await pairContract?.token1()
            setTokenB(token1)
            console.log("token1", token1)

            let tokenAErc20 = new Contract(token0, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            let tokenBErc20 = new Contract(token1, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            const tokenADataDecimals: any = await tokenAErc20?.decimals()
            setTokenADecimals(tokenADataDecimals)
            const tokenBDataDecimals: any = await tokenBErc20?.decimals()
            setTokenBDecimals(tokenBDataDecimals)

            let reserves = await pairContract?.getReserves()

            setTokenAReserves(reserves._reserve0.toString());
            setTokenBReserves(reserves._reserve1.toString());
        } catch (error) {
            console.log(error)
            setAccountPairAmount("0");
            setPairTotal("0");
            setTokenAReserves("0");
            setTokenBReserves("0");
        }
    }
    return (<>
        {
            new BigNumber(accountPairAmount).isZero() ? <></> : <div className="pairItem bg">
                <div className="header flex">
                    <div className=" flex-1 flex font16 blackOrwhite" >
                        <div >
                            <TokenAandTokenB tokenA={tokenA} tokenB={tokenB} />
                        </div>
                        <div className=" fontw500">
                            <span style={{
                                position: "relative",
                                top: "2px",
                                margin: "2px 3px 0px"
                            }}>
                                <TokenName token={tokenA} />
                                /
                                <TokenName token={tokenB} />
                            </span>
                        </div>

                    </div>
                    <div onClick={() => {
                        setOpen(!open)
                    }} style={{
                        cursor: "pointer"
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            style={{
                                marginLeft: "8px",
                                height: "20px",
                                minWidth: "20px",
                                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                                color: "#f3b71e"
                            }}
                        ><polyline points="6 9 12 15 18 9"></polyline></svg>
                    </div>
                </div>
                {
                    open && <div>
                        <div className="flex blackOrwhite font16" style={{
                            marginTop: "12px"
                        }}>
                            <div className="flex-1">{t("yourTotalPoolTokens")}:</div>
                            <div>
                                {accountPairAmount == "0" ? 0 : trimNumber(fromTokenValue(accountPairAmount, 18, 6), 6)}
                            </div>
                        </div>
                        <div className="flex blackOrwhite font16" style={{
                            marginTop: "5px"
                        }}>
                            <div className="flex-1">{t("Pooled")} <TokenName token={tokenA} />:</div>
                            <div>
                                {
                                    trimNumber(fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenADecimals), 3), 3)
                                }
                            </div>
                        </div>

                        <div className="flex blackOrwhite font16" style={{
                            marginTop: "5px"
                        }}>
                            <div className="flex-1">{t("Pooled")} <TokenName token={tokenB} />:</div>
                            <div>
                                {
                                    trimNumber(fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenBDecimals), 3), 3)
                                }
                            </div>
                        </div>

                        <div className="flex blackOrwhite font16" style={{
                            marginTop: "5px"
                        }}>
                            <div className="flex-1">{t("yourPoolShare")}:</div>
                            <div>
                                {accountPairAmount == "0" ? 0 : <>
                                    {
                                        new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01) ? "<0.01" : new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01)
                                    }
                                </>}%
                            </div>
                        </div>

                        <div className="flex pairItemBtn">
                            <div className="flex-1">
                                <p onClick={() => {
                                    navigate('/pool/add/' + tokenA + '/' + tokenB);
                                }}>
                                    {t("add")}
                                </p>
                            </div>
                            <div className="flex-1">
                                <p style={{
                                    position: "relative",
                                    float: "right",
                                }} onClick={() => {
                                    navigate('/pool/remove/' + tokenA + '/' + tokenB);
                                }}>
                                    {t("remove")}
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </>)
}

export default PairItem