import './index.css';
import TokenIcon from './tokenIcon';

interface ITokenAandTokenB {
    tokenA: string
    tokenB: string
}

function TokenAandTokenB({ tokenA, tokenB }: ITokenAandTokenB) {
    return (
        <div className='flex'>
            <div className='tokenA'>
                <TokenIcon token={tokenA} />
            </div>
            <div className='tokenB'>
                <TokenIcon token={tokenB} />
            </div>
        </div>
    )
}

export default TokenAandTokenB