import React from 'react'
import TokenIcon from './tokenIcon'
import TokenName from './tokenName'

interface ISelectToken {
    token: string
}

function SelectToken({ token }: ISelectToken) {
    return (
        <p className=" blackOrwhite fontw500 font18">
            <TokenIcon token={token} /> <span style={{
                position: "relative",
                top: "2px",
                margin: "2px 3px 0px"
            }}><TokenName token={token} /></span>  <svg className="sc-9ag4mq-8 euovgw" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M0.97168 1L6.20532 6L11.439 1" stroke="currentColor"></path></svg>
        </p>
    )
}

export default SelectToken