import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

function ChangeLanguage() {
    const { t } = useTranslation()

    const changeLanguage = () => {
        let changeLanguageStr
        if (i18n.language == "zh_CN") {
            changeLanguageStr = 'en_US'
        } else {
            changeLanguageStr = 'zh_CN'
        }
        i18n.changeLanguage(changeLanguageStr);
    }

    return (
        <div className='textCenter'>
            <p className="textColor">Fstswap {t("availablein")}: <span className='mainColor' onClick={() => {
                changeLanguage()
            }}>{t("changeLanguageText")}</span></p>
        </div>
    )
}

export default ChangeLanguage;