import { useEffect, useState } from 'react'
import { BNB, BTCB, BUSD, DAI, ETH, FIST, FON, OSK, TOMATO, TOMATOS, USD, USDC, USDT, WHY, XFST, XFSTNFT, notFund } from '../../image'
import { useERC20 } from '../../hooks/useContract';
interface ITokenIcon {
    token: string
}
function TokenIcon({ token }: ITokenIcon) {

    const tokenContract = useERC20(token);
    const [name, setName] = useState<string>();

    useEffect(() => {
        if (token) {
            getName();
        }
    }, [tokenContract, name]);

    const getName = async () => {
        if (token == "BNB" || token == process.env.REACT_APP_TOKEN_BNB) {
            setName("BNB");
        } else {
            try {
                let symbol = await tokenContract?.symbol()
                setName(symbol);
            } catch (error) {
                setName("notFund");
            }
        }
    };

    const getHtml = (str: string) => {
        let html;
        if (str == "BNB") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={BNB} alt="" />;
        } else if (str == "USDT") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={USDT} alt="" />;
        } else if (str == "BTCB") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={BTCB} alt="" />;
        } else if (str == "XFST") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={XFST} alt="" />;
        }else if (str == "Tomatos") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={TOMATOS} alt="" />;
        }else if (str == "Tomato") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={TOMATO} alt="" />;
        } else if (str == "FON") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={FON} alt="" />;
        } else if (str == "WHY") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={WHY} alt="" />;
        } else if (str == "FIST") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={FIST} alt="" />;
        } else if (str == "OSK") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={OSK} alt="" />;
        } else if (str == "ETH") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={ETH} alt="" />;
        } else if (str == "BUSD") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={BUSD} alt="" />;
        } else if (str == "USDC") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={USDC} alt="" />;
        } else if (str == "USD") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={USD} alt="" />;

        } else if (str == "DAI") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={DAI} alt="" />;
        } else if (str == "notFund") {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={notFund} alt="" />
        } else {
            html = <img style={{
                width: "24px",
                height: "24px",
                borderRadius: "24px"
            }} src={notFund} alt="" />
        }

        return html;
    }

    return (<>
        {
            name && getHtml(name)
        }
    </>
    )
}

export default TokenIcon