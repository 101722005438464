import { useEffect, useState } from 'react'
import { useERC20 } from '../../hooks/useContract';
import BigNumber from "bignumber.js";
import { trimNumber } from '../../utils';
const ethers = require('ethers');
declare const window: Window & { ethereum: any };

interface ITokenBalance {
    token: string
    addr: string
    decimalPlaces: number,
    setTokenBalance?: Function,
    change?: boolean
}

function TokenBalance({ token, addr, decimalPlaces, setTokenBalance, change }: ITokenBalance) {
    const tokenContract = useERC20(token);
    const [decimals, setDecimals] = useState<number>(18);
    const [balance, setBalance] = useState<string>('0');

    useEffect(() => {
        getBalance()
    }, [tokenContract, change]);

    const getBalance = async () => {
        if (token == "BNB" || token == process.env.REACT_APP_TOKEN_CAKEBNB) {
            // if (token == "BNB") {
            let provider = new ethers.providers.Web3Provider(window.ethereum)
            const balance = await provider.getBalance(addr + "")
            console.log("token", balance.toString())
            setDecimals(18);
            setBalance(balance.toString());
            if (setTokenBalance) setTokenBalance(balance.toString());

        } else {
            try {
                let balance = await tokenContract?.balanceOf(addr);
                setBalance(balance.toString());
                if (setTokenBalance) setTokenBalance(balance.toString());
                let decimals = await tokenContract?.decimals()
                setDecimals(Number(decimals.toString()));
            } catch (error) {
                setBalance("0")
                setDecimals(18);
            }
        };
    }

    return <>{
        trimNumber(new BigNumber(balance).dividedBy(10 ** decimals).toFixed(decimalPlaces), decimalPlaces)
    }</>
}

export default TokenBalance