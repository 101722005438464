import { useEffect, useState } from "react";
import { useERC20 } from "../../hooks/useContract";

interface ITokenName {
    token: string
}
function TokenName({ token }: ITokenName) {

    const tokenContract = useERC20(token);
    const [name, setName] = useState<string>();

    useEffect(() => {
        if (token) {
            getName();
        }
    }, [tokenContract, name]);

    const getName = () => {
        if (token == "BNB" || token == process.env.REACT_APP_TOKEN_BNB||token==process.env.REACT_APP_TOKEN_CAKEBNB) {
            setName("BNB");
        } else {
            tokenContract?.symbol().then(function (ret: any) {
                setName(ret);
            }).catch(function (err: any) {
            });
        }
    };

    return <>{name}</>
}

export default TokenName