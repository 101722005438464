import { useEffect, useState } from "react";
import FooterNav from "../../components/footerNav/footerNav";
import TokenBalance from "../../components/token/tokenBalance";
import './swap.css';
import { ITokenData, MAX_UNIT256 } from "../../constants";
import { useWeb3React } from "@web3-react/core";
import TokenName from "../../components/token/tokenName";
import BigNumber from "bignumber.js";
import { verify } from "../../utils/formatting";
import { formatNumber, fromTokenValue, toTokenValue } from "../../utils";
import { getProviderOrSigner, useFactoryContract, useFistFactoryContract, useFistRouterContract, useRouterContract } from "../../hooks/useContract";
import ERC20ABI from '../../abis/erc20.json';
import { Contract } from "ethers";
import { Modal, Popover } from "antd";
import Tooltip from "antd/es/tooltip";
import { useNavigate } from "react-router-dom";
import TipPop from "../../components/pop/TipPop";
import TokenCyPop from "../../components/pop/tokenCyPop";
import PairABI from '../../abis/Pair.json';
import { AddressZero } from '@ethersproject/constants'
import TokenIcon from "../../components/token/tokenIcon";
import ChangeLanguage from "../../components/changeLanguage/changeLanguage";
import { useTranslation } from "react-i18next";
import { notFund } from "../../image";
import SelectToken from "../../components/token/selectToken";


const ethers = require('ethers');
const FIST = process.env.REACT_APP_TOKEN_FIST + "";
const USDT = process.env.REACT_APP_TOKEN_USDT + "";
function Swap() {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const { account, library } = useWeb3React()
    const fistFactoryContract = useFistFactoryContract();
    const factoryContract = useFactoryContract();
    const fistRouterContract = useFistRouterContract();
    const routerContract = useRouterContract();

    const [change, setChange] = useState<boolean>(true);

    const [tokenIn, setTokenIn] = useState<ITokenData>({
        amount: '',
        token: USDT,
        amountView: '',
    });

    const [tokenOut, setTokenOut] = useState<ITokenData>({
        amount: '',
        token: FIST,
        amountView: '',
    });

    const [slipValue, setSlipValue] = useState<string>('0.5');
    const [time, setTime] = useState<string>('1800');

    const [tokenPopOpen, setTpkenPopOpen] = useState<boolean>(false);
    const [tokenPopOpenType, setTpkenPopOpenType] = useState<string>("in");

    const [tokenADecimals, setTokenADecimals] = useState<string>("0")
    const [tokenBDecimals, setTokenBDecimals] = useState<string>("0")

    const [tokenABalance, setTokenABalance] = useState<string>("0")

    const [tokenABalanceAllowance, setTokenABalanceAllowance] = useState<string>("0")

    const [isFistContrat, setIsFistContrat] = useState<boolean>(true);

    const [pairRouterAddr, setPairRouterAddr] = useState<string>("")
    const [pairFistRouterAddr, setPairFistRouterAddr] = useState<string>("")

    const [approveLoading, setApporveLoading] = useState<boolean>(false);
    const [confirmSend, setConfirmSend] = useState<boolean>(false);
    const [viewNet, setViewnet] = useState<boolean>(false);
    const [viewHash, setViewhash] = useState<string>("");
    const [loadingSend, setLoadingSend] = useState<boolean>(false);


    const [state1, setState1] = useState<boolean>(false);
    const [state2, setState2] = useState<boolean>(false);
    useEffect(() => {
        setState1(false)
        setState2(false)
        getDecimals(tokenIn.token, tokenOut.token)
        getPairRouter()
        getPairFistRouter()
    }, [account, tokenIn.token, tokenOut.token])

    useEffect(() => {
        if (state1 && state2) {
            getData()
        }
    }, [pairFistRouterAddr, pairRouterAddr, account])


    const getData = async () => {
        console.log("data1", pairFistRouterAddr)
        console.log("data2", pairRouterAddr)

        if (pairFistRouterAddr != AddressZero && pairRouterAddr != AddressZero) {
            console.log("data1", 11)
            let pairContract = new Contract(pairRouterAddr, PairABI, getProviderOrSigner(library, account || "") as any);
            let pairBalance = await pairContract?.totalSupply();

            let fistPairContract = new Contract(pairFistRouterAddr, PairABI, getProviderOrSigner(library, account || "") as any);
            let fistPairBalance = await fistPairContract?.totalSupply();
            console.log(new BigNumber(fistPairBalance.toString()).isGreaterThanOrEqualTo(pairBalance.toString()),">>>>>>",fistPairBalance.toString(),pairBalance.toString())
            if (new BigNumber(fistPairBalance.toString()).isGreaterThanOrEqualTo(pairBalance.toString())) {
                console.log("data1", 111)
                setIsFistContrat(true);
                dataInfo(fistRouterContract?.address + "")
            } else {
                console.log("data1", 1111)
                setIsFistContrat(false);
                dataInfo(routerContract?.address + "")
            }

        } else if (pairFistRouterAddr != AddressZero && pairRouterAddr == AddressZero) {
            console.log("data1", 22)
            setIsFistContrat(true);
            dataInfo(fistRouterContract?.address + "")

        } else if (pairFistRouterAddr == AddressZero && pairRouterAddr != AddressZero) {
            setIsFistContrat(false);
            dataInfo(routerContract?.address + "")
        }
    }

    const dataInfo = async (addr: string) => {
        let tokenInToken = tokenIn.token

        if (tokenIn.token == "BNB") {
            tokenInToken = process.env.REACT_APP_TOKEN_BNB + ""
        }

        let tokenAErc20 = new Contract(tokenInToken, ERC20ABI, getProviderOrSigner(library, account || "") as any);
        const tokenAAllowance: any = await tokenAErc20?.allowance(account, addr);
        console.log("data 4", addr, tokenAAllowance)
        setTokenABalanceAllowance(tokenAAllowance.toString())
    }


    useEffect(() => {
        getDecimals(tokenIn.token, tokenOut.token)
        getPairRouter()
        getPairFistRouter()
    })

    const init = () => {
        getPairRouter()
        getPairFistRouter()
        tokenIn.amount = "";
        tokenIn.amountView = "";
        tokenOut.amount = "";
        tokenOut.amountView = "";
        setChange(!change)
    }
    const initApprove = () => {
        if (isFistContrat) {
            dataInfo(fistRouterContract?.address + "")
        } else {
            dataInfo(routerContract?.address + "")
        }
    }

    const getPairRouter = async () => {
        let tokenInCake = tokenIn.token
        let tokenOutCake = tokenOut.token

        if (tokenIn.token == "BNB") {
            tokenInCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
            tokenOutCake = tokenOut.token
        }

        if (tokenOut.token == "BNB") {
            tokenInCake = tokenIn.token
            tokenOutCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }

        try {
            let date = await factoryContract?.getPair(tokenInCake, tokenOutCake);
            console.log("getPairRouter1", date)
            setPairRouterAddr(date)
            setState1(true)
        } catch (error) {
            setState1(true)
            setPairRouterAddr(AddressZero)
        }
    }

    const getPairFistRouter = async () => {
        let tokenInFist = tokenIn.token
        let tokenOutFist = tokenOut.token

        if (tokenIn.token == "BNB") {
            tokenInFist = process.env.REACT_APP_TOKEN_BNB + ""
            tokenOutFist = tokenOut.token
        }

        if (tokenOut.token == "BNB") {
            tokenInFist = tokenIn.token
            tokenOutFist = process.env.REACT_APP_TOKEN_BNB + ""
        }

        try {
            let date = await fistFactoryContract?.getPair(tokenInFist, tokenOutFist);
            setPairFistRouterAddr(date)
            setState2(true)
            console.log("getPairRouter2", date)

        } catch (error) {
            setState2(true)
            console.log("getPairRouter2")
            setPairFistRouterAddr(AddressZero)
        }
    }

    const getDecimals = async (tokena: string, tokenb: string) => {
        if (tokena == "BNB") {
            setTokenADecimals("18")
        } else {
            let tokenInErc20 = new Contract(tokena, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            const tokenInDecimals: any = await tokenInErc20?.decimals()
            setTokenADecimals(tokenInDecimals)
        }

        if (tokenb == "BNB") {
            setTokenBDecimals("18")
        } else {
            let tokenOutErc20 = new Contract(tokenb, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            const tokenOutDecimals: any = await tokenOutErc20?.decimals()
            setTokenBDecimals(tokenOutDecimals)
        }
    }

    const changeSwap = () => {
        let dataIn = tokenIn;
        let dataOut = tokenOut;
        setTokenIn(dataOut);
        setTokenOut(dataIn);
    };

    const MaxIn = () => {
        tokenIn.amount = tokenABalance;
        tokenIn.amountView = fromTokenValue(tokenABalance, Number(tokenADecimals), 4);
        setTokenIn({ ...tokenIn });
        if (tokenIn.token == tokenOut.token) {
            tokenOut.amount = tokenABalance;
            tokenOut.amountView = fromTokenValue(tokenABalance, Number(tokenADecimals), 4);
            setTokenOut({ ...tokenOut });
            return
        }
        if (tokenOut.token) {
            getAmountsOut();
        }
    }

    const onChangeIn = async (e: any) => {
        let value = e.target.value;
        value = verify(value);
        tokenIn.amount = toTokenValue(value, Number(tokenADecimals));
        tokenIn.amountView = value;
        setTokenIn({ ...tokenIn });
        if (tokenIn.token == tokenOut.token) {
            tokenOut.amount = toTokenValue(value, Number(tokenADecimals));
            tokenOut.amountView = new BigNumber(value).toFixed(4);
            setTokenOut({ ...tokenOut });
            return
        }
        if (tokenOut.token) {
            getAmountsOut();
        }
    };

    const onChangeOut = (e: any) => {
        let value = e.target.value;
        value = verify(value);
        tokenOut.amount = toTokenValue(value, Number(tokenBDecimals));
        tokenOut.amountView = value;

        setTokenOut({ ...tokenOut });
        if (tokenIn.token == tokenOut.token) {
            tokenIn.amount = toTokenValue(value, Number(tokenBDecimals));
            tokenIn.amountView = new BigNumber(value).toFixed(4);
            setTokenIn({ ...tokenIn });
            return
        }
        if (tokenIn.token) {
            getAmountsIn();
        }
    };

    const getAmountsIn = async () => {
        if (!tokenOut.amount || tokenOut.amount == '0') {
            tokenIn.amount = "";
            tokenIn.amountView = "";
            setTokenIn({ ...tokenIn });
            return
        }

        if (new BigNumber(tokenOut.amount).isZero()) {
            return;
        }
        let tokenInCake = tokenIn.token
        let tokenOutCake = tokenOut.token

        if (tokenIn.token == "BNB") {
            tokenInCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        if (tokenOut.token == "BNB") {
            tokenOutCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }

        try {
            let dataContract = isFistContrat ? fistRouterContract : routerContract
            console.log(">>>>>",dataContract?.address)
            let data = await dataContract?.getAmountsIn(tokenOut.amount, [tokenInCake, tokenOutCake])
            tokenIn.amount = data[0].toString();
            tokenIn.amountView = formatNumber(fromTokenValue(data[0].toString(), Number(tokenADecimals)), 4) + "";
            setTokenIn({ ...tokenIn });

        } catch (error) {
            console.log(error)
        }
    };

    const getAmountsOut = async () => {
        if (!tokenIn.amount || tokenIn.amount == '0') {
            tokenOut.amount = "";
            tokenOut.amountView = "";
            setTokenOut({ ...tokenOut });
            return
        }
        if (new BigNumber(tokenIn.amount).isZero()) {
            return;
        }
        let tokenInCake = tokenIn.token
        let tokenOutCake = tokenOut.token
        if (tokenIn.token == "BNB") {
            tokenInCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        if (tokenOut.token == "BNB") {
            tokenOutCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        try {
            let dataContract = isFistContrat ? fistRouterContract : routerContract
            console.log(">>>>",dataContract?.address)
            let data = await dataContract?.getAmountsOut(tokenIn.amount, [tokenInCake, tokenOutCake])
            tokenOut.amount = data[1].toString();
            tokenOut.amountView = formatNumber(fromTokenValue(data[1].toString(), Number(tokenBDecimals)), 4) + "";
            setTokenOut({ ...tokenOut });
        } catch (error) {
            console.log(error)
        }
    };

    const amountZero = () => {
        tokenOut.amount = "";
        tokenOut.amountView = "";
        setTokenOut({ ...tokenOut });
        tokenIn.amount = "";
        tokenIn.amountView = "";
        setTokenIn({ ...tokenIn });
    }

    const sendSwap = async () => {

        let tokenInCake = tokenIn.token
        let tokenOutCake = tokenOut.token

        // //setLoadingSend(true)
        setConfirmSend(false)
        if (tokenIn.token == process.env.REACT_APP_TOKEN_BNB || tokenIn.token == "BNB") {
            tokenInCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }

        if (tokenOut.token == process.env.REACT_APP_TOKEN_BNB || tokenOut.token == "BNB") {
            tokenOutCake = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }

        let sendSlipValue = "0"
        if (slipValue == "") {
            sendSlipValue = "0.5"
        } else {
            sendSlipValue = slipValue
        }

        let deadline = parseInt(new Date().getTime() / 1000 + "") + Number(time);

        let amountOutMin = new BigNumber(tokenOut.amount).multipliedBy((100 - Number(sendSlipValue)) / 100).toFixed(0, 1);

        let amountIn = tokenIn.amount
        let dataContract = isFistContrat ? fistRouterContract : routerContract
        console.log("dataContract?.address", dataContract?.address)
        try {

            if (tokenInCake == process.env.REACT_APP_TOKEN_CAKEBNB + "") {
                try {
                    const gas: any = await dataContract?.estimateGas.swapExactETHForTokens(amountOutMin, [tokenInCake, tokenOutCake], account, deadline, {
                        from: account,
                        value: amountIn
                    })
                    const response = await dataContract?.swapExactETHForTokens(amountOutMin,
                        [tokenInCake, tokenOutCake], account, deadline, {
                        from: account,
                        value: amountIn,
                        gasLimit: gas.mul(105).div(100)
                    })
                    setViewhash(response.hash)
                    let provider = new ethers.providers.Web3Provider(library.provider);
                    let receipt = await provider.waitForTransaction(response.hash);
                    if (receipt !== null) {
                        init()
                        amountZero()
                        setViewnet(true)
                        setConfirmSend(false)
                    }
                } catch (error) {
                    // //setLoadingSend(false)
                    setConfirmSend(true)
                }
            } else if (tokenOutCake == process.env.REACT_APP_TOKEN_CAKEBNB + "") {

                try {
                    const gas: any = await dataContract?.estimateGas.swapExactTokensForETH(amountIn, amountOutMin, [tokenInCake, tokenOutCake], account, deadline, {
                        from: account
                    })
                    const response = await dataContract?.swapExactTokensForETH(amountIn, amountOutMin, [tokenInCake, tokenOutCake], account, deadline, {
                        from: account,
                        gasLimit: gas.mul(105).div(100)
                    })
                    setViewhash(response.hash)
                    let provider = new ethers.providers.Web3Provider(library.provider);
                    let receipt = await provider.waitForTransaction(response.hash);
                    if (receipt !== null) {
                        init()
                        amountZero()
                        setViewnet(true)
                        setConfirmSend(false)
                    }
                } catch (error) {
                    // //setLoadingSend(false)
                    setConfirmSend(true)
                }
            } else {
                console.log(3)

                console.log("send", tokenIn.amount, amountOutMin, [tokenInCake, tokenOutCake], account, deadline)
                
                try {
                    // swapExactTokensForTokensSupportingFeeOnTransferTokens
                    // swapExactTokensForTokensSupportingFeeOnTransferTokens
                    // swapExactTokensForTokens

                    // if(tokenInCake=="0x734002CcE40a8bA6E58D67E05d1864514A351dA6"){
                    //     amountOutMin="0"
                    // }
                    const gas: any = await dataContract?.estimateGas.swapExactTokensForTokensSupportingFeeOnTransferTokens(tokenIn.amount, amountOutMin, [tokenInCake, tokenOutCake], account, deadline, {
                        from: account
                    })
                    const response = await dataContract?.swapExactTokensForTokensSupportingFeeOnTransferTokens(tokenIn.amount, amountOutMin, [tokenInCake, tokenOutCake], account, deadline, {
                        from: account,
                        gasLimit: gas.mul(110).div(100)
                    })
                    setViewhash(response.hash)
                    let provider = new ethers.providers.Web3Provider(library.provider);
                    let receipt = await provider.waitForTransaction(response.hash);
                    if (receipt !== null) {
                        init()
                        amountZero()
                        setViewnet(true)
                        setConfirmSend(false)
                    }
                } catch (error) {
                    // //setLoadingSend(false)
                    setConfirmSend(true)
                }
            }
        } catch (error) {
            // //setLoadingSend(false)
            setConfirmSend(true)
        }
    };

    const sendApprove = async (token: any, approveAddress: string) => {

        if (token == "BNB") {
            token = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        setApporveLoading(true)
        console.log(token, approveAddress)
        let approveContract = new Contract(token, ERC20ABI, getProviderOrSigner(library, account || "") as any);
        try {
            const gas: any = await approveContract?.estimateGas.approve(approveAddress, MAX_UNIT256, { from: account });
            const response = await approveContract?.approve(approveAddress, MAX_UNIT256, {
                from: account,
                gasLimit: gas.mul(105).div(100)
            });
            let provider = new ethers.providers.Web3Provider(library.provider);
            let receipt = await provider.waitForTransaction(response.hash);
            if (receipt !== null) {

                if (receipt !== null) {
                    if (receipt.status && receipt.status == 1) {
                        initApprove()
                        setTimeout(() => {
                            setApporveLoading(false)
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setApporveLoading(false)
                        }, 1000);
                    }
                }
            }
        } catch (err: any) {
            setTimeout(() => {
                setApporveLoading(false)
            }, 1000);
        }
    }


    const getSetHtml = () => {
        return <div className='setContent setbg '>
            <div style={{
                marginBottom: "10px"
            }}>
                <p className='textColor'>{t("TransactionSettings")} </p>
            </div>
            <div style={{
                marginBottom: "5px"
            }}>
                <p className='textColor'>{t("SlippageTolerance")} <Tooltip placement="top" title={
                    <div>
                        <p className="textColor">{t("SlippageTolerance1")}</p>
                    </div>
                }>
                    <span>？</span>
                </Tooltip></p>
            </div>

            <div className=' flex'>
                <div className="slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setSlipValue('0.1')
                        }} className={Number(slipValue) == 0.1 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>0.1 %</span>
                    </p>
                </div>
                <div className=" slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setSlipValue("0.2")
                        }} className={Number(slipValue) == 0.2 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>0.2 %</span>
                    </p>
                </div>
                <div className=" slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setSlipValue("0.5")
                        }} className={Number(slipValue) == 0.5 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>0.5%</span>
                    </p>
                </div>
                <div className="slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setSlipValue("1")
                        }} className={Number(slipValue) == 1 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>1.0%</span>
                    </p>
                </div>

            </div>

            <div className="slipItemInput">
                <input type="text" value={slipValue} onChange={(e) => {
                    let value = e.target.value;
                    value = verify(value);
                    setSlipValue(value)
                }} placeholder='0.0' />
                <p className="blackOrwhite">%</p>
            </div>

            <div style={{
                marginBottom: "5px"
            }}>
                <p className='textColor'>{t("Transactiondeadline")} <Tooltip placement="top" title={
                    <div>
                        <p className="textColor">{t("Transactiondeadline1")}</p>
                    </div>
                }>
                    <span>？</span>
                </Tooltip></p>
            </div>

            <div className=' flex'>
                <div className="slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setTime('600')
                        }} className={Number(time) == 600 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>10分钟</span>
                    </p>
                </div>

                <div className="slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setTime('1200')
                        }} className={Number(time) == 1200 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>20分钟</span>
                    </p>
                </div>
                <div className="slipItem flex-1">
                    <p>
                        <span onClick={() => {
                            setTime('1800')
                        }} className={Number(time) == 1800 ? "selectSlip" : "blackOrwhite bg1 borderColor"}>30分钟</span>
                    </p>
                </div>

            </div>

            <div className="slipItemTime">
                <input type="text" value={time == "" ? "" : new BigNumber(time).dividedBy(60).toString()} onChange={(e) => {
                    let value = e.target.value;
                    value = verify(value);
                    if (value == "0" || value == "0.") {
                        console.log(1)
                        setTime(value)
                    } else if (value == "") {
                        console.log(2)
                        setTime("")
                    } else {
                        setTime(new BigNumber(value).multipliedBy(60).toString())
                    }
                }} placeholder='0.0' />
                <p className="blackOrwhite"> {t("minutes")}</p>
            </div>
        </div>
    }

    return (<>
        <TipPop hash={viewHash} />

        <Modal
            className='LoadingBox'
            open={loadingSend}
            centered
            bodyStyle={{
                borderRadius: "16px"
            }}
            footer={null}
            closeIcon={null}
        >
            <div className='bg LoadingBoxInfo' >
                <div className="flex">
                    <div className="flex-1"></div>
                    <div>
                        <svg onClick={() => {
                            //setLoadingSend(false)
                        }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                </div>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    padding: "20px 0"
                }}>
                    <svg className="rotate" width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92" stroke="#2172E5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div>
                    <p className="font18 fontw500 textCenter blackOrwhite">{t("WaitingForConfirmation")}</p>
                    <div className="textCenter blackOrwhite" style={{ padding: "10px 0 !importeant" }}>
                        {t("Swapping")}
                        &nbsp;
                        {tokenIn.amountView}
                        &nbsp;
                        <TokenName token={tokenIn.token + ""} />
                        &nbsp;
                        {t("for")}
                        &nbsp;
                        {tokenOut.amountView}
                        &nbsp;
                        <TokenName token={tokenOut.token + ""} />
                    </div>
                    <p className="textCenter textColor"> {t("ConfirmWallet")}</p>
                </div>
            </div>
        </Modal>

        <Modal
            className='LoadingBox'
            open={confirmSend}
            centered
            bodyStyle={{
                borderRadius: "16px"
            }}
            footer={null}
            closeIcon={null}
        >
            <div className='bg1 LoadingBoxInfo' >
                <div className="flex">
                    <div className="flex-1">
                        <p className=" fontw500  blackOrwhite">{t("ConfirmSwap")}</p>
                    </div>
                    <div>
                        <svg onClick={() => {
                            setConfirmSend(false)
                        }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                </div>

                <div className="exchangeBox ">
                    <div className=" flex currenHeader bg borderColor blackOrwhite">
                        {t("From")}
                    </div>
                    <div className="currenFoote bg borderColor textColor flex">
                        <div className="flex-1 flex blackOrwhite">
                            <TokenIcon token={tokenIn.token} />
                            <div className="font18 fontw500" style={{
                                lineHeight: "24px",
                                marginLeft: "10px"
                            }}>
                                <TokenName token={tokenIn.token + ""} />
                            </div>
                        </div>
                        <div>
                            {tokenIn.amountView}
                        </div>
                    </div>
                </div>

                <div className="exchangeIcon bg borderColorWhiteOrBlack textColor" onClick={() => {
                    changeSwap()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                </div>

                <div className="exchangeBox ">
                    <div className=" flex currenHeader bg borderColor blackOrwhite">
                        {t("To")}
                    </div>
                    <div className="currenFoote bg borderColor textColor flex">
                        <div className="flex-1 flex blackOrwhite">
                            <TokenIcon token={tokenOut.token} />
                            <div className="font18 fontw500" style={{
                                lineHeight: "24px",
                                marginLeft: "10px"
                            }}>
                                <TokenName token={tokenOut.token + ""} />
                            </div>
                        </div>
                        <div>
                            {tokenOut.amountView}
                        </div>
                    </div>
                </div>

                <div className="flex " style={{
                    fontSize: "12px",
                    margin: "10px 0px"
                }}>
                    <div className="flex-1 blackOrwhite">
                        {t("Price")}
                    </div>
                    <div>
                        <p className="blackOrwhite " > 1 <TokenName token={tokenIn.token} /> = {new BigNumber(tokenOut.amountView).dividedBy(tokenIn.amountView).toFixed(3)}<TokenName token={tokenOut.token} /></p>
                    </div>
                </div>

                <div className="exchangeDetail bg borderColor" style={{
                    marginBottom: "10px",
                    border: "1px solid",
                    padding: "10px",
                    fontSize: "12px",
                    borderRadius: "16px"
                }}>
                    <div className="flex">
                        <div className="flex-1 textColor">
                            {t("LiquidityProviderFee")}
                        </div>
                        <div className="blackOrwhite">
                            {new BigNumber(tokenIn.amountView).multipliedBy(3).dividedBy(1000).toFixed(3)} <TokenName token={tokenIn.token} />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex-1 textColor">
                            {t("Route")}
                        </div>
                        <div className="blackOrwhite flex">
                            <TokenName token={tokenIn.token} />
                            <div style={{
                                paddingTop: "2px"
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                            <TokenName token={tokenOut.token} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-1 textColor">
                            {t("PriceImpact")}
                        </div>
                        <div className="blackOrwhite">0.00%</div>
                    </div>

                    <div className="flex">
                        <div className="flex-1 textColor">
                            {t("MinimumReceived")}
                        </div>
                        <div className="blackOrwhite">
                            {new BigNumber(tokenIn.amountView).multipliedBy(new BigNumber(1).plus(new BigNumber(slipValue).dividedBy(100).toString()).toString()).toFixed(2)}
                            <TokenName token={tokenIn.token} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-1 textColor">
                            {t("SlippageTolerance")}
                        </div>
                        <div className="blackOrwhite">
                            {new BigNumber(slipValue).toFixed(2)}%
                        </div>
                    </div>
                </div>

                <div className="textColor" style={{
                    fontSize: "12px",
                    marginTop: "10px"
                }}>
                    {t("ConfirmSwap1")}  154.36 FIST {t("ConfirmSwap2")}
                </div>

                <div className="sendBtn" >
                    <p onClick={() => {
                        sendSwap()
                    }}>{t("ConfirmSwap")}</p>
                </div>
            </div>
        </Modal>

        <Modal
            className='LoadingBox'
            open={viewNet}
            centered
            bodyStyle={{
                borderRadius: "16px"
            }}
            footer={null}
            closeIcon={null}
        >
            <div className='bg LoadingBoxInfo' >
                <div className="flex">
                    <div className="flex-1"></div>
                    <div>
                        <svg onClick={() => {
                            setViewnet(false)
                        }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                </div>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    padding: "20px 0"
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="90px" viewBox="0 0 24 24" fill="none" stroke="#F3B71E" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg>
                </div>

                <div>
                    <p className="font18 fontw500 textCenter">{t("TransactionSubmitted")}</p>
                    <p className="fontw500 textCenter" style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        color: "#F3B71E"
                    }}
                        onClick={() => {
                            window.open("https://bscscan.com/tx/" + viewHash, '_blank');
                        }}
                    >{t("ViewonExplorer")}</p>
                </div>

                <div className="sendBtn" >
                    <p onClick={() => {
                        setViewnet(false)
                        //setLoadingSend(false)
                        setConfirmSend(false)
                    }}> {t("Close")}</p>
                </div>
            </div>
        </Modal>
        <TokenCyPop open={tokenPopOpen} setOpen={setTpkenPopOpen} tokenType={tokenPopOpenType} tokenIn={tokenIn} setTokenIn={setTokenIn} tokenOut={tokenOut} setTokenOut={setTokenOut} />


        <div className='mainContent '>
            <div className='bg1 swapBox '>
                <div className="flex">
                    <div className="flex-1" style={{
                        marginBottom: "10px"
                    }}>
                        <p className=" font16 fontw500 blackOrwhite">{t("Swap")}</p>
                    </div>
                    <div>
                        <Popover placement="bottomRight" content={getSetHtml} trigger="click">
                            <svg className="sc-ow6uye-0 dKgVBY textColor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                        </Popover>
                    </div>
                </div>
                <div className="exchangeBox ">
                    <div className=" flex currenHeader bg borderColor">
                        <div className="selectPop btnHover bg1" onClick={() => {
                            setTpkenPopOpenType("in")
                            setTpkenPopOpen(true)
                        }}>
                            <SelectToken token={tokenIn.token} />
                        </div>

                        <div className="inputBox flex-1">
                            <input className='bg textColor' type="text" value={tokenIn.amountView} onChange={(e) => {
                                onChangeIn(e)
                            }} placeholder='0.0' />
                        </div>
                    </div>
                    <div className="currenFoote bg borderColor textColor">
                        <p>
                            {t("Balance")}:
                            <TokenBalance token={tokenIn.token} addr={account + ''} decimalPlaces={3} setTokenBalance={setTokenABalance} change={change} />
                            <span className="mainColor" onClick={() => {
                                MaxIn()
                            }}>({t("Max")})</span>
                        </p>
                    </div>
                </div>

                <div className="exchangeIcon bg borderColorWhiteOrBlack textColor" onClick={() => {
                    changeSwap()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                </div>
                <div className="exchangeBox " style={{
                    marginBottom: "10px"
                }}>
                    <div className=" flex currenHeader bg borderColor">
                        <div className="selectPop btnHover bg1" onClick={() => {
                            setTpkenPopOpenType("out")
                            setTpkenPopOpen(true)
                        }}>
                            <SelectToken token={tokenOut.token} />
                        </div>

                        <div className="inputBox flex-1">
                            <input className='bg textColor' type="text" value={tokenOut.amountView} onChange={(e) => {
                                onChangeOut(e)
                            }} placeholder='0.0' />
                        </div>
                    </div>

                    <div className="currenFoote bg borderColor textColor">
                        <p>{t("Balance")}:<TokenBalance token={tokenOut.token} addr={account + ''} decimalPlaces={3} change={change} /></p>
                    </div>
                </div>
                {
                    tokenIn.token !== tokenOut.token && new BigNumber(tokenIn.amount).isGreaterThan(0) && new BigNumber(tokenOut.amount).isGreaterThan(0) && <div className="exchangeDetail" style={{
                        marginBottom: "10px"
                    }}>
                        <div className=" exchangePrice" style={{
                            marginBottom: "10px"
                        }}>
                            <p className="blackOrwhite font16" > 1 <TokenName token={tokenIn.token} /> = {new BigNumber(tokenOut.amountView).dividedBy(tokenIn.amountView).toFixed(3)}<TokenName token={tokenOut.token} /></p>
                        </div>

                        <div className="flex">
                            <div className="flex-1 textColor">
                                {t("LiquidityProviderFee")}
                            </div>
                            <div className="blackOrwhite">
                                {new BigNumber(tokenIn.amountView).multipliedBy(3).dividedBy(1000).toFixed(3)} <TokenName token={tokenIn.token} />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-1 textColor">
                                {t("Route")}
                            </div>
                            <div className="blackOrwhite flex">
                                <TokenName token={tokenIn.token} />
                                <div style={{
                                    paddingTop: "2px"
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                </div>
                                <TokenName token={tokenOut.token} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="flex-1 textColor">
                                {t("PriceImpact")}
                            </div>
                            <div className="blackOrwhite">0.00%</div>
                        </div>

                        <div className="flex">
                            <div className="flex-1 textColor">
                                {t("MinimumReceived")}
                            </div>
                            <div className="blackOrwhite">
                                {new BigNumber(tokenIn.amountView).multipliedBy(new BigNumber(1).plus(new BigNumber(slipValue).dividedBy(100).toString()).toString()).toFixed(2)}
                                <TokenName token={tokenIn.token} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="flex-1 textColor">
                                {t("SlippageTolerance")}
                            </div>
                            <div className="blackOrwhite">
                                {new BigNumber(slipValue).toFixed(2)}%
                            </div>
                        </div>
                    </div>
                }

                {
                    tokenIn.token == tokenOut.token ? <div className="sendBtnNo bg textColor" >
                        <p>({t("selectToken")})</p>
                    </div> : <>
                        {
                            tokenIn.amount == "" && tokenOut.amount == "" ? <div className="sendBtnNo bg textColor" >
                                <p> {t("Enteranamount")} </p>
                            </div> : <>
                                {
                                    new BigNumber(tokenABalance).isLessThan(tokenIn.amount) ? <div className="sendBtnNo bg textColor" >
                                        <p>  <TokenName token={tokenIn.token} />{t("Insufficient")} </p>
                                    </div> : <>
                                        {
                                            tokenIn.token != "BNB" && new BigNumber(tokenABalanceAllowance).isLessThan(tokenIn.amount) ? <div className="sendBtn" >
                                                <p style={{
                                                    fontSize: "16px"
                                                }} onClick={() => {
                                                    let dataContract = isFistContrat ? fistRouterContract : routerContract
                                                    sendApprove(tokenIn.token, dataContract?.address + "")
                                                }}>
                                                    <img style={{
                                                        width: "22px",
                                                        height: "22px",
                                                        borderRadius: "22px",
                                                        position: "relative",
                                                        top: "-3px"
                                                    }} src={notFund} alt="" /> <span > {t("AllowtheFstswapProtocoltouseyour")}</span> <TokenName token={tokenIn.token} />
                                                    {
                                                        approveLoading ? <svg style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            marginLeft: "10px",
                                                            position: "relative",
                                                            top: "2px"
                                                        }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="white" className="sc-bftyxn-0 ldGXgk rotate"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> : <></>
                                                    }
                                                </p>
                                            </div> : <></>
                                        }

                                        {
                                            tokenIn.token != "BNB" && new BigNumber(tokenABalanceAllowance).isLessThan(tokenIn.amount) ? <div className="sendBtnNo bg textColor" >
                                                <p > {t("swap")} </p>
                                            </div> : <div className="sendBtn" >
                                                <p onClick={() => {
                                                    setConfirmSend(true)
                                                }}> {t("swap")} </p>
                                            </div>
                                        }
                                    </>
                                }

                            </>
                        }
                    </>
                }
                <div className="poolHomeLink">
                    <p onClick={() => {
                        navigate('/home/' + tokenIn.token + "/" + tokenOut.token);
                    }}>
                        Pool detail
                    </p>
                </div>
            </div>

            <div style={{
                paddingTop: "20px"
            }}>
                <ChangeLanguage />
            </div>
        </div>

        <div className="footer bg1 borderColor ">
            <FooterNav />
        </div>
    </>)
}

export default Swap