import FooterNav from "../../components/footerNav/footerNav"
import './recentTransactions.css';
import RecentTransactionsBoxContent from "./recentTransactionsBoxContent";

function RecentTransactions() {

    return (<>
        <div className='mainContent'>
            <div className='bg1 recentTransactionsBox '>
                <RecentTransactionsBoxContent />
            </div>
        </div>
        <div className="footer bg1 borderColor ">
            <FooterNav />
        </div>
    </>)
}

export default RecentTransactions
