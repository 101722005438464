import { useNavigate, useParams } from "react-router-dom";
import FooterNav from "../../../components/footerNav/footerNav"
import './poolAdd.css';
import TokenName from "../../../components/token/tokenName";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import TokenBalance from "../../../components/token/tokenBalance";
import { Contract } from "ethers";
import { getProviderOrSigner, useFactoryContract, useFistFactoryContract, useFistRouterContract } from "../../../hooks/useContract";
import PairABI from '../../../abis/Pair.json';
import ERC20ABI from '../../../abis/erc20.json';
import { fromTokenValue, toTokenValue, trimNumber } from "../../../utils";
import BigNumber from "bignumber.js";
import { verify } from "../../../utils/formatting";
import TokenAandTokenB from "../../../components/token/tokenAandTokenB";
import { MAX_UNIT256 } from "../../../constants";
import TipPop from "../../../components/pop/TipPop";
import TokenCyPop from "../../../components/pop/tokenCyPop";
import TokenIcon from "../../../components/token/tokenIcon";
import { useTranslation } from "react-i18next";
import { AddressZero } from '@ethersproject/constants'
import ChangeLanguage from "../../../components/changeLanguage/changeLanguage";
import { Modal } from "antd";
import SelectToken from "../../../components/token/selectToken";

const ethers = require('ethers');

function PoolAdd() {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation()

    const { account, library } = useWeb3React()
    const fistFactoryContract = useFistFactoryContract();
    const fistRouterContract = useFistRouterContract();


    const [tokenA, setTokenA] = useState<string>();
    const [tokenB, setTokenB] = useState<string>();
    const [tokenAAmount, setTokenAAmount] = useState<string>('');
    const [tokenBAmount, setTokenBAmount] = useState<string>('');

    const [tokenAAmountSend, setTokenAAmountSend] = useState<string>('');
    const [tokenBAmountSend, setTokenBAmountSend] = useState<string>('');

    const [tokenAtoTokenB, setTokenAtoTokenB] = useState<string>("0")
    const [tokenBtoTokenA, setTokenBtoTokenA] = useState<string>("0")

    const [tokenADecimals, setTokenADecimals] = useState<string>("0")
    const [tokenBDecimals, setTokenBDecimals] = useState<string>("0")

    const [accountPairAmount, setAccountPairAmount] = useState<string>("0")
    const [pairTotal, setPairTotal] = useState<string>("0")

    const [tokenAReserves, setTokenAReserves] = useState<string>("0")
    const [tokenBReserves, setTokenBReserves] = useState<string>("0")

    const [pairAddr, setPairAddr] = useState<string>("")

    // const [loading, setLoading] = useState<boolean>(false);
    // const [loadingState, setLoadingState] = useState<string>("loading")
    // const [loadingText, setLoadingText] = useState<string>("")

    const [tokenPopOpen, setTpkenPopOpen] = useState<boolean>(false);
    const [tokenPopOpenType, setTpkenPopOpenType] = useState<string>("in");

    const [tokenABalance, setTokenABalance] = useState<string>("0")
    const [tokenABalanceAllowance, setTokenABalanceAllowance] = useState<string>("0")

    const [tokenBBalance, setTokenBBalance] = useState<string>("0")
    const [tokenBBalanceAllowance, setTokenBBalanceAllowance] = useState<string>("0")

    const [change, setChange] = useState<boolean>(true);

    const [loadingSend, setLoadingSend] = useState<boolean>(false);
    const [confirmSend, setConfirmSend] = useState<boolean>(false);
    const [approveLoading, setApporveLoading] = useState<boolean>(false);
    const [viewNet, setViewnet] = useState<boolean>(false);
    const [viewHash, setViewhash] = useState<string>("");


    useEffect(() => {
        init()
    }, [params, account]);

    const init = () => {
        setChange(!change)
        setTokenAAmount("")
        setTokenBAmount("")
        setTokenAAmountSend("")
        setTokenAAmountSend("")
        let tokena = params.tokenA || ""
        let tokenb = params.tokenB || ""

        if (tokena == "BNB") {
            tokena = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        if (tokenb == "BNB") {
            tokenb = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        setTokenA(tokena);
        setTokenB(tokenb);
        getAmountsOut(tokena, tokenb)
        getPair(tokena, tokenb)
    }

    const approveInit = () => {
        if (tokenA && tokenB) getAmountsOut(tokenA, tokenB)
    }

    const getAmountsOut = async (tokena: string, tokenb: string) => {
        try {
            let tokenAErc20 = new Contract(tokena, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            let tokenBErc20 = new Contract(tokenb, ERC20ABI, getProviderOrSigner(library, account || "") as any);
            const tokenADataDecimals: any = await tokenAErc20?.decimals()
            setTokenADecimals(tokenADataDecimals)
            const tokenBDataDecimals: any = await tokenBErc20?.decimals()
            setTokenBDecimals(tokenBDataDecimals)
            const allowancetokena: any = await tokenAErc20?.allowance(account, fistRouterContract?.address);
            setTokenABalanceAllowance(allowancetokena.toString())
            const allowancetokenb: any = await tokenBErc20?.allowance(account, fistRouterContract?.address);
            setTokenBBalanceAllowance(allowancetokenb.toString())
            let info = await fistRouterContract?.getAmountsOut(toTokenValue(1, tokenADataDecimals), [tokena, tokenb])
            console.log("info", info[0].toString(), info[1].toString())
            setTokenAtoTokenB(info[1].toString())
            let info1 = await fistRouterContract?.getAmountsOut(toTokenValue(1, tokenBDataDecimals), [tokenb, tokena])
            setTokenBtoTokenA(info1[1].toString())

        } catch (error) {
            setTokenAtoTokenB("0")
        }
    }

    const getPair = async (tokena: string, tokenb: string) => {
        try {

            let date = await fistFactoryContract?.getPair(tokena, tokenb)

            setPairAddr(date)
            let pairContract = new Contract(date, PairABI, getProviderOrSigner(library, account || "") as any);

            let pairBalance = await pairContract?.balanceOf(account)
            setAccountPairAmount(pairBalance.toString())

            let total = await pairContract?.totalSupply()
            setPairTotal(total.toString())

            let token0 = await pairContract?.token0()
            console.log("token0", token0)

            let reserves = await pairContract?.getReserves()
            console.log("getReserves", reserves, reserves._reserve0.toString(), reserves._reserve1.toString())

            if (tokena == token0) {
                setTokenAReserves(reserves._reserve0.toString());
                setTokenBReserves(reserves._reserve1.toString());
            } else {
                setTokenAReserves(reserves._reserve1.toString());
                setTokenBReserves(reserves._reserve0.toString());
            }

        } catch (error) {
            setPairAddr("")
            setAccountPairAmount("0");
            setPairTotal("0");
            setTokenAReserves("0");
            setTokenBReserves("0");
        }
    }

    const Max = (type: string) => {
        if (tokenAReserves != '0' && tokenBReserves != '0') {
            if (type == "in") {
                setTokenAAmount(fromTokenValue(tokenABalance, Number(tokenADecimals), 4));
                setTokenAAmountSend(tokenABalance)
                setTokenBAmount(new BigNumber(fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(tokenABalance).div(new BigNumber(tokenAReserves)).toFixed(), Number(tokenBDecimals))).toFixed(4));
                setTokenBAmountSend(new BigNumber(tokenBReserves).multipliedBy(tokenABalance).div(new BigNumber(tokenAReserves)).toFixed());
            } else {
                setTokenAAmount(new BigNumber(fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(tokenBBalance).div(new BigNumber(tokenBReserves)).toFixed(), Number(tokenADecimals))).toFixed(4));
                setTokenAAmountSend(new BigNumber(tokenAReserves).multipliedBy(tokenBBalance).div(new BigNumber(tokenBReserves)).toFixed());
                setTokenBAmount(fromTokenValue(tokenBBalance, Number(tokenBDecimals), 4));
                setTokenBAmountSend(tokenBBalance)
            }
        } else {
            if (type == "in") {
                setTokenAAmount(fromTokenValue(tokenABalance, Number(tokenADecimals), 4));
                setTokenAAmountSend(tokenABalance)
                setTokenBAmount(tokenBAmount);
                setTokenBAmountSend(toTokenValue(tokenBAmount, Number(tokenBDecimals)))
            } else {
                setTokenAAmount(new BigNumber(tokenAAmount).toFixed(4));
                setTokenAAmountSend(toTokenValue(tokenAAmount, Number(tokenADecimals)))
                setTokenBAmount(fromTokenValue(tokenBBalance, Number(tokenBDecimals), 4));
                setTokenBAmountSend(tokenBBalance)
            }
        }
    }

    const onChangeAmount = (amount0: string, amount1: string, type?: string) => {
        if (tokenAReserves != '0' && tokenBReserves != '0') {
            if (amount0.length == 0 || amount1.length == 0) {
                setTokenAAmount("");
                setTokenAAmountSend("")
                setTokenBAmount("");
                setTokenBAmountSend("")
                return;
            }

            if (new BigNumber(amount0).isZero() && new BigNumber(amount1).isZero()) {
                setTokenAAmount(amount0);
                setTokenAAmountSend(toTokenValue(amount0, Number(tokenADecimals)))
                setTokenBAmount(amount1);
                setTokenBAmountSend(toTokenValue(amount1, Number(tokenBDecimals)))
                return;
            }

            if (amount0 != '0') {
                setTokenAAmount(amount0);
                setTokenAAmountSend(toTokenValue(amount0, Number(tokenADecimals)))
                setTokenBAmount(new BigNumber(fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(toTokenValue(amount0, Number(tokenADecimals))).div(new BigNumber(tokenAReserves)).toFixed(), Number(tokenBDecimals))).toFixed(6));
                setTokenBAmountSend(new BigNumber(tokenBReserves).multipliedBy(toTokenValue(amount0, Number(tokenADecimals))).div(new BigNumber(tokenAReserves)).toFixed());
            } else {
                setTokenAAmount(new BigNumber(fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(toTokenValue(amount1, Number(tokenBDecimals))).div(new BigNumber(tokenBReserves)).toFixed(), Number(tokenADecimals))).toFixed(6));
                setTokenAAmountSend(new BigNumber(tokenAReserves).multipliedBy(toTokenValue(amount1, Number(tokenBDecimals))).div(new BigNumber(tokenBReserves)).toFixed());
                setTokenBAmount(amount1);
                setTokenBAmountSend(toTokenValue(amount1, Number(tokenBDecimals)))
            }
        } else {
            if (new BigNumber(type + "").isZero()) {
                setTokenAAmount(amount0);
                setTokenAAmountSend(toTokenValue(amount0, Number(tokenADecimals)))
                setTokenBAmount(tokenBAmount);
                setTokenBAmountSend(toTokenValue(tokenBAmount, Number(tokenBDecimals)))
            } else {
                setTokenAAmount(tokenAAmount);
                setTokenAAmountSend(toTokenValue(tokenAAmount, Number(tokenADecimals)))
                setTokenBAmount(amount1);
                setTokenBAmountSend(toTokenValue(amount1, Number(tokenBDecimals)))
            }
        }
    }

    const sendAdd = async () => {
        // //setLoadingSend(true)
        setConfirmSend(false)
        let deadline = parseInt(new Date().getTime() / 1000 + "") + 120;

        if (tokenA == process.env.REACT_APP_TOKEN_CAKEBNB || tokenA == "BNB") {
            try {
                const gas: any = await fistRouterContract?.estimateGas.addLiquidityETH(tokenB, toTokenValue(tokenBAmount, Number(tokenBDecimals)), 0, 0, account, deadline, {
                    from: account,
                    value: toTokenValue(tokenAAmount, Number(tokenADecimals))
                })

                const response = await fistRouterContract?.addLiquidityETH(tokenB, toTokenValue(tokenBAmount, Number(tokenBDecimals)), 0, 0, account, deadline, {
                    from: account,
                    value: toTokenValue(tokenAAmount, Number(tokenADecimals)),
                    gasLimit: gas.mul(105).div(100)
                })
                setViewhash(response.hash)
                let provider = new ethers.providers.Web3Provider(library.provider);
                let receipt = await provider.waitForTransaction(response.hash);

                if (receipt !== null) {
                    init()
                    setViewnet(true)
                    setConfirmSend(false)
                }
            } catch (error) {
                // //setLoadingSend(false)
                setConfirmSend(true)
            }

        } else if (tokenB == process.env.REACT_APP_TOKEN_CAKEBNB || tokenB == "BNB") {
            try {
                const gas: any = await fistRouterContract?.estimateGas.addLiquidityETH(tokenA, toTokenValue(tokenAAmount, Number(tokenADecimals)), 0, 0, account, deadline, { from: account, value: toTokenValue(tokenBAmount, Number(tokenBDecimals)) })

                const response = await fistRouterContract?.addLiquidityETH(tokenA, toTokenValue(tokenA, Number(tokenADecimals)), 0, 0, account, deadline, { from: account, value: toTokenValue(tokenBAmount, Number(tokenBDecimals)), gasLimit: gas.mul(105).div(100) })
                setViewhash(response.hash)

                let provider = new ethers.providers.Web3Provider(library.provider);
                let receipt = await provider.waitForTransaction(response.hash);
                if (receipt !== null) {
                    init()
                    setViewnet(true)
                    setConfirmSend(false)
                }
            } catch (error) {
                // //setLoadingSend(false)
                setConfirmSend(true)
            }
        } else {
            console.log(tokenAAmountSend, tokenBAmountSend)
            try {
                const gas: any = await fistRouterContract?.estimateGas.addLiquidity(tokenA, tokenB, new BigNumber(tokenAAmountSend).toFixed(0), new BigNumber(tokenBAmountSend).toFixed(0), 0, 0, account, deadline, { from: account })

                const response = await fistRouterContract?.addLiquidity(tokenA, tokenB, new BigNumber(tokenAAmountSend).toFixed(0), new BigNumber(tokenBAmountSend).toFixed(0), 0, 0, account, deadline, { from: account, gasLimit: gas.mul(105).div(100) })
                setViewhash(response.hash)

                let provider = new ethers.providers.Web3Provider(library.provider);
                let receipt = await provider.waitForTransaction(response.hash);
                if (receipt !== null) {
                    init()
                    setViewnet(true)
                    setConfirmSend(false)
                }
            } catch (error) {
                //setLoadingSend(false)
                setConfirmSend(true)
            }
        }
    }

    const sendApprove = async (token: any, approveAddress: string) => {

        if (token == "BNB") {
            token = process.env.REACT_APP_TOKEN_CAKEBNB + ""
        }
        setApporveLoading(true)
        let approveContract = new Contract(token, ERC20ABI, getProviderOrSigner(library, account || "") as any);

        try {
            const gas: any = await approveContract?.estimateGas.approve(approveAddress, MAX_UNIT256, { from: account });
            const response = await approveContract?.approve(approveAddress, MAX_UNIT256, {
                from: account,
                gasLimit: gas.mul(105).div(100)
            });
            let provider = new ethers.providers.Web3Provider(library.provider);

            let receipt = await provider.waitForTransaction(response.hash);
            if (receipt !== null) {
                if (receipt.status && receipt.status == 1) {
                    approveInit()
                    setTimeout(() => {
                        setApporveLoading(false)
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setApporveLoading(false)
                    }, 1000);
                }
            }
        } catch (err: any) {
            setTimeout(() => {
                setApporveLoading(false)
            }, 1000);
        }
    }

    return (
        <>
            <TipPop hash={viewHash} />
            <TokenCyPop open={tokenPopOpen} setOpen={setTpkenPopOpen} tokenType={tokenPopOpenType} linkType={"add"} linkTokenA={tokenA} linkTokenB={tokenB} />
            <Modal
                className='LoadingBox'
                open={loadingSend}
                centered
                bodyStyle={{
                    borderRadius: "16px"
                }}
                footer={null}
                closeIcon={null}
            >
                <div className='bg LoadingBoxInfo' >
                    <div className="flex">
                        <div className="flex-1"></div>
                        <div>
                            <svg onClick={() => {
                                //setLoadingSend(false)
                            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </div>

                    <div style={{
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px 0"
                    }}>
                        <svg className="rotate" width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92" stroke="#2172E5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>

                    <div>
                        <p className="font18 fontw500 textCenter blackOrwhite">{t("WaitingForConfirmation")} </p>
                        <div className="textCenter blackOrwhite" style={{ padding: "10px 0 !importeant" }}>
                            {t("Supplying")}
                            &nbsp;
                            {tokenAAmount}
                            &nbsp;
                            <TokenName token={tokenA + ""} />
                            &nbsp;
                            {t("and")}
                            &nbsp;
                            {tokenBAmount}
                            &nbsp;
                            <TokenName token={tokenB + ""} />
                        </div>
                        <p className="textCenter textColor">{t("ConfirmWallet")}</p>
                    </div>
                </div>
            </Modal>

            <Modal
                className='LoadingBox'
                open={confirmSend}
                centered
                bodyStyle={{
                    borderRadius: "16px"
                }}
                footer={null}
                closeIcon={null}
            >
                <div className='bg LoadingBoxInfo' >
                    <div className="flex">
                        <div className="flex-1">
                            <p className=" fontw500  blackOrwhite">{t("Youwillreceive")}</p>
                        </div>
                        <div>
                            <svg onClick={() => {
                                setConfirmSend(false)
                            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </div>

                    <div className="blackOrwhite " style={{
                        padding: "8px 0px 12px 0px",
                        fontSize: "20px",
                        fontWeight: "bold"
                    }}>
                        {
                            fromTokenValue(new BigNumber(toTokenValue(tokenAAmount, Number(tokenADecimals))).dividedBy(new BigNumber(tokenAReserves).plus(toTokenValue(tokenAAmount, Number(tokenADecimals)))).multipliedBy(pairTotal).toFixed(6), 18, 6)
                        }
                    </div>
                    <div className="blackOrwhite font18" style={{
                        paddingBottom: "5px"
                    }}>
                        <TokenName token={tokenA + ""} />/<TokenName token={tokenB + ""} /> Pool Tokens
                    </div>
                    <div className="textColor" style={{
                        fontSize: "12px"
                    }}>
                        {t("Youwillreceive1")}
                    </div>

                    <div className="flex blackOrwhite" style={{
                        paddingBottom: "5px"
                    }}>
                        <div className="flex-1" style={{
                            lineHeight: "28px"
                        }}>
                            {t("Deposited")}<TokenName token={tokenA + ""} />
                        </div>
                        <div className="flex">
                            <TokenIcon token={tokenA + ""} />
                            <div style={{
                                lineHeight: "24px",
                                marginLeft: "10px"
                            }}>
                                {tokenAAmount}
                            </div>
                        </div>
                    </div>

                    <div className="flex blackOrwhite" style={{
                        paddingBottom: "5px"
                    }}>
                        <div className="flex-1" style={{
                            lineHeight: "28px"
                        }}>
                            {t("Deposited")} <TokenName token={tokenB + ""} />
                        </div>
                        <div className="flex">
                            <TokenIcon token={tokenB + ""} />
                            <div style={{
                                lineHeight: "24px",
                                marginLeft: "10px"
                            }}>
                                {tokenBAmount}
                            </div>
                        </div>
                    </div>
                    <div className="flex blackOrwhite">
                        <div className="flex-1">
                            {t("Rates")}
                        </div>
                        <div >
                            <div style={{
                                paddingBottom: "5px"
                            }}>
                                1 <TokenName token={tokenA + ""} /> ={fromTokenValue(tokenAtoTokenB, Number(tokenBDecimals), 4)}<TokenName token={tokenB + ""} />
                            </div>
                            <div>
                                1 <TokenName token={tokenB + ""} /> ={fromTokenValue(tokenBtoTokenA, Number(tokenADecimals), 4)}<TokenName token={tokenA + ""} />
                            </div>
                        </div>
                    </div>

                    <div className="sendBtn" >
                        <p onClick={() => {
                            sendAdd()
                        }}> {t("ConfirmSupply")}</p>
                    </div>
                </div>
            </Modal>

            <Modal
                className='LoadingBox'
                open={viewNet}
                centered
                bodyStyle={{
                    borderRadius: "16px"
                }}
                footer={null}
                closeIcon={null}
            >
                <div className='bg LoadingBoxInfo' >
                    <div className="flex">
                        <div className="flex-1"></div>
                        <div>
                            <svg onClick={() => {
                                setViewnet(false)
                            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </div>

                    <div style={{
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px 0"
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="90px" viewBox="0 0 24 24" fill="none" stroke="#F3B71E" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg>
                    </div>

                    <div>
                        <p className="font18 fontw500 textCenter">{t("TransactionSubmitted")}</p>
                        <p className="fontw500 textCenter" style={{
                            fontSize: "12px",
                            cursor: "pointer",
                            color: "#F3B71E"
                        }}
                            onClick={() => {
                                window.open("https://bscscan.com/tx/" + viewHash, '_blank');
                            }}
                        >{t("ViewonExplorer")}</p>
                    </div>

                    <div className="sendBtn" >
                        <p onClick={() => {
                            setViewnet(false)
                            //setLoadingSend(false)
                            setConfirmSend(false)
                        }}> {t("Close")}</p>
                    </div>
                </div>
            </Modal>

            {/* <TipPop open={loading} setOpen={setLoading} loadingText={loadingText} loadingState={loadingState} /> */}
            <div className='mainContent'>
                <div className='bg1 poolAddBox '>
                    <div className="poolheader flex">
                        <div onClick={() => {
                            navigate('/pool')
                        }}>
                            <svg className="sc-1tguxka-4 kSTWMO blackOrwhite" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                        </div>
                        <div className="flex-1">
                            <p className=" fontw500 font18 textCenter blackOrwhite"> {t("poolAddtitle")}</p>
                        </div>
                    </div>

                    <div className="messageInfo">
                        <p className="mainColor font16">{t("poolAddtitle1")}</p>
                    </div>

                    <div className="exchangeBox " style={{
                        padding: "20px 0px"
                    }}>
                        <div className=" flex currenHeader bg borderColor">
                            <div className="selectPop btnHover bg1" onClick={() => {
                                setTpkenPopOpen(true)
                                setTpkenPopOpenType("in")
                            }}>
                                <SelectToken token={tokenA + ""} />
                            </div>

                            <div className="inputBox flex-1">
                                <input className='bg textColor' type="text" value={tokenAAmount} onChange={(e) => {
                                    let value = e.target.value;
                                    onChangeAmount(verify(value), '0', "0");
                                }} placeholder='0.0' />
                            </div>
                        </div>

                        <div className="currenFoote bg borderColor textColor">
                            <p>
                                {t("Balance")}:
                                <TokenBalance token={tokenA + ""} addr={account + ''} decimalPlaces={3} setTokenBalance={setTokenABalance} change={change} />
                                <span className="mainColor" onClick={() => { Max("in") }}>({t("Max")})</span>
                            </p>
                        </div>
                    </div>

                    <div className="textCenter textColor">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </div>

                    <div className="exchangeBox " style={{
                        padding: "20px 0px"
                    }}>
                        <div className=" flex currenHeader bg borderColor">
                            <div className="selectPop btnHover bg1" onClick={() => {
                                setTpkenPopOpen(true)
                                setTpkenPopOpenType("out")
                            }}>
                                <SelectToken token={tokenB + ""} />
                            </div>

                            <div className="inputBox flex-1">
                                <input className='bg textColor' type="text" value={tokenBAmount} onChange={(e) => {
                                    let value = e.target.value;
                                    onChangeAmount('0', verify(value), "1");
                                }} placeholder='0.0' />
                            </div>
                        </div>

                        <div className="currenFoote bg borderColor textColor">
                            <p>
                                {t("Balance")}:
                                <TokenBalance token={tokenB + ""} addr={account + ''} decimalPlaces={3} setTokenBalance={setTokenBBalance} change={change} />
                                <span className="mainColor" onClick={() => { Max("out") }}>({t("Max")})</span>
                            </p>
                        </div>
                    </div>

                    <div className="poolexchange bg borderColor" style={{
                        marginBottom: "20px"
                    }}>
                        <div className="label">
                            <p className="blackOrwhite font16">{t("PricesAndPoolShare")}</p>
                        </div>
                        <div className="content borderColor flex">
                            <div className=" flex-1 textCenter">
                                <p className="blackOrwhite">{trimNumber(fromTokenValue(tokenAtoTokenB, Number(tokenBDecimals), 4), 4)}</p>
                                <p className="textColor">
                                    <TokenName token={tokenA + ""} />{t("per")}<TokenName token={tokenB + ""} />
                                </p>
                            </div>
                            <div className=" flex-1 textCenter">
                                <p className="blackOrwhite">{trimNumber(fromTokenValue(tokenBtoTokenA, Number(tokenADecimals), 6), 6)}</p>
                                <p className="textColor">
                                    <TokenName token={tokenB + ""} />  {t("per")} <TokenName token={tokenA + ""} />
                                </p>
                            </div>
                            <div className=" flex-1 textCenter">
                                <p className="blackOrwhite">
                                    {tokenAAmount !== "" ? <>
                                        {
                                            new BigNumber(toTokenValue(tokenAAmount, Number(tokenADecimals))).dividedBy(new BigNumber(tokenAReserves).plus(toTokenValue(tokenAAmount, Number(tokenADecimals)))).multipliedBy(100).isLessThan(0.01) ? "<0.01" : trimNumber(new BigNumber(toTokenValue(tokenAAmount, Number(tokenADecimals))).dividedBy(new BigNumber(tokenAReserves).plus(toTokenValue(tokenAAmount, Number(tokenADecimals)))).multipliedBy(100).toFixed(6), 6)
                                        }
                                    </> : "0.00"}%
                                </p>
                                <p className="textColor">
                                    {t("ShareOfPool")}
                                </p>
                            </div>
                        </div>
                    </div>

                    {
                        tokenAAmountSend == "" && tokenBAmountSend == "" ? <div className="sendBtnNo bg textColor" >
                            <p> {t("Enteranamount")} </p>
                        </div> : <>
                            {
                                new BigNumber(tokenABalance).isLessThan(tokenAAmountSend) ? <div className="sendBtnNo bg textColor" >
                                    <p>  <TokenName token={tokenA + ""} />{t("Insufficient")} </p>
                                </div> : <>
                                    {
                                        new BigNumber(tokenBBalance).isLessThan(tokenBAmountSend) ? <div className="sendBtnNo bg textColor" >
                                            <p>  <TokenName token={tokenB + ""} />{t("Insufficient")} </p>
                                        </div> : <>
                                            {
                                                tokenA != "BNB" && new BigNumber(tokenABalanceAllowance).isLessThan(tokenAAmountSend) ? <div className="sendBtn" >
                                                    <p onClick={() => {
                                                        sendApprove(tokenA, fistRouterContract?.address + "")
                                                    }}>
                                                        {t("Approve")}  <TokenName token={tokenA + ""} />

                                                        {
                                                            approveLoading ? <svg style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                marginLeft: "10px",
                                                                position: "relative",
                                                                top: "2px"
                                                            }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="white" className="sc-bftyxn-0 ldGXgk rotate"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> : <></>
                                                        }
                                                    </p>
                                                </div> : <>
                                                    {
                                                        tokenB != "BNB" && new BigNumber(tokenBBalanceAllowance).isLessThan(tokenBAmountSend) ? <div className="sendBtn" >
                                                            <p onClick={() => {
                                                                sendApprove(tokenB, fistRouterContract?.address + "")
                                                            }}> {t("Approve")} <TokenName token={tokenB + ""} />
                                                                {
                                                                    approveLoading ? <svg style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginLeft: "10px",
                                                                        position: "relative",
                                                                        top: "2px"
                                                                    }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="white" className="sc-bftyxn-0 ldGXgk rotate"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> : <></>
                                                                }

                                                            </p>
                                                        </div> : <>
                                                            <div className="sendBtn" >
                                                                <p onClick={() => {
                                                                    setConfirmSend(true)
                                                                }}> {t("Supply")} </p>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </div>

                <div style={{
                    paddingTop: "20px"
                }}>
                    <ChangeLanguage />
                </div>

                {
                    pairAddr !== AddressZero ? <div className="poolDetailBox bg2">
                        <div>
                            <p className="blackOrwhite font16"> {t("YourPosition")}</p>
                        </div>
                        <div className="flex blackOrwhite " style={{
                            padding: "8px 0px"
                        }}>
                            <div className="flex-1">
                                <div className="flex font18">
                                    <TokenAandTokenB tokenA={tokenA + ""} tokenB={tokenB + ""} />
                                    <TokenName token={tokenA + ""} />
                                    /
                                    <TokenName token={tokenB + ""} />
                                </div>

                            </div>
                            <div className="font18"><TokenBalance token={pairAddr} addr={account + ""} decimalPlaces={6} change={change} /></div>
                        </div>
                        <div className="flex blackOrwhite font16">
                            <div className="flex-1">{t("YourPoolShare")}:</div>
                            <div>
                                {accountPairAmount == "0" ? 0 : <>
                                    {
                                        new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01) ? "<0.01" : new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01)
                                    }
                                </>}%
                            </div>
                        </div>
                        <div className="flex blackOrwhite font16">
                            <div className="flex-1"><TokenName token={tokenA + ""} />:</div>
                            <div>
                                {
                                    fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenADecimals), 3)
                                }
                            </div>
                        </div>
                        <div className="flex blackOrwhite font16">
                            <div className="flex-1"><TokenName token={tokenB + ""} />:</div>
                            <div>
                                {
                                    fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenBDecimals), 3)
                                }
                            </div>
                        </div>
                    </div> : <></>
                }
            </div>
            <div className="footer bg1 borderColor ">
                <FooterNav />
            </div>
        </>
    )
}

export default PoolAdd