import { useState } from 'react'
import './my.less'
import less from 'less';
import i18n from '../../i18n';
import { dark, light } from '../../constants';
import { useTranslation } from 'react-i18next';
import FooterNav from '../../components/footerNav/footerNav';
import { useNavigate } from 'react-router-dom';

function My() {

    const { t } = useTranslation()
    const navigate = useNavigate();
    const [isDark, setIsDark] = useState<boolean>(false);



    const changeLanguage = () => {
        let changeLanguageStr

        if (i18n.language == "zh_CN") {
            changeLanguageStr = 'en_US'
        } else {
            changeLanguageStr = 'zh_CN'
        }

        i18n.changeLanguage(changeLanguageStr);
    }

    return (
        <>
            <div className='mainContent'>
                <div className='bg1 mybox '>
                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor'>
                            {t("MyNode")}
                        </div>
                        <div className='textColor'>
                            <svg className='myboxItemImg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity="0.6"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                        </div>
                    </div>

                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor'>
                            {t("Code")}
                        </div>
                        <div className='textColor'>
                            <svg  onClick={() => {
                                window.open("https://bscscan.com/address/0x9A272d734c5a0d7d84E0a892e891a553e8066dce#code", '_blank');
                            }} className='myboxItemImg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity="0.6"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                        </div>
                    </div>

                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor' onClick={() => {
                            navigate("/recentTransactions");
                        }}>
                            {t("RecentTransactions")}
                        </div>
                        <div className='textColor'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                        </div>
                    </div>

                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor'>
                            {t("Language")}
                        </div>
                        <div className='textColor' onClick={() => {
                            changeLanguage()
                        }}>
                            {t("LanguageText")}
                        </div>
                    </div>

                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor'>
                            {
                                isDark ? <>{t("LightTheme")}</> : <> {t("DarkTheme")}</>
                            }
                        </div>
                        <div className='textColor' onClick={() => {
                            if (isDark) {
                                less.modifyVars(light)
                            } else {
                                less.modifyVars(dark)
                            }
                            setIsDark(!isDark)
                        }}>
                            {
                                isDark ? <svg className='myboxItemImg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity="0.6"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg> : <svg className='myboxItemImg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity="0.6"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                            }
                        </div>
                    </div>

                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor'>
                            Telegram
                        </div>
                        <div className='textColor'>
                            <svg
                                onClick={() => {
                                    window.open("https://t.me/FstSwapFinance", '_blank');
                                }}
                                className='myboxItemImg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity="0.6"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                        </div>
                    </div>

                    <div className='flex myboxItem borderColor'>
                        <div className='flex-1 textColor'>
                            Twitter
                        </div>
                        <div className='textColor'>
                            <svg onClick={() => {
                                window.open("https://twitter.com/FstSwapFinance", '_blank');
                            }} className='myboxItemImg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" opacity="0.6"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer bg1 borderColor ">
                <FooterNav />
            </div>
        </>
    )
}

export default My