import {
  ConfigProvider,
} from 'antd';
import React from 'react';
import './App.less'
import getLibrary from './utils/getLibrary'
import { Web3ReactProvider } from '@web3-react/core';
import Web3NetworkProvider from './components/web3/Web3NetworkProvider';
import Web3ReactManager from './components/web3/Web3RectManager';
import { HashRouter, Route, Routes } from 'react-router-dom';
import HeaderComponent from './components/headerComponent';
import Home from './page/home/home';
import My from './page/my/my';
import Pool from './page/pool/pool';
import Swap from './page/swap/swap';
import PoolAdd from './page/pool/add/poolAdd';
import PoolRemove from './page/pool/remove/poolRemove';
import AddPair from './page/pool/addPair/addPair';
import RecentTransactions from './page/recentTransactions/recentTransactions';



const App: React.FC = () => {
  
  return (
    <ConfigProvider>
      <div className='app bg'>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ReactManager>
            <Web3NetworkProvider>
              <HashRouter>
                <HeaderComponent />
                <Routes >
                  <Route path="/" element={<Home />} />
                  <Route path="/home/:tokenA/:tokenB" element={<Home />} />
                  <Route path="/swap" element={<Swap />} />
                  <Route path="/pool" element={<Pool />} />
                  <Route path="/pool/addPair" element={<AddPair />} />
                  <Route path="/pool/add/:tokenA/:tokenB" element={<PoolAdd />} />
                  <Route path="/pool/remove/:tokenA/:tokenB" element={<PoolRemove />} />
                  <Route path="/my" element={<My />} />
                  <Route path="/recentTransactions" element={<RecentTransactions />} />
                </Routes>
              </HashRouter>
            </Web3NetworkProvider>
          </Web3ReactManager>
        </Web3ReactProvider>
      </div>
    </ConfigProvider>

  );
};

export default App;