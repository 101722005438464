import { useEffect, useState } from "react";
import FooterNav from "../../../components/footerNav/footerNav";
import TipPop from "../../../components/pop/TipPop";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "antd/lib/slider";
import './poolRemove.css';
import TokenName from "../../../components/token/tokenName";
import { fromTokenValue, toTokenValue } from "../../../utils";
import BigNumber from "bignumber.js";
import ERC20ABI from '../../../abis/erc20.json';
import PairABI from '../../../abis/Pair.json';
import { Contract } from "ethers";
import { getProviderOrSigner, useFistFactoryContract, useFistRouterContract } from "../../../hooks/useContract";
import { useWeb3React } from "@web3-react/core";
import { MAX_UNIT256 } from "../../../constants";
import TokenAandTokenB from "../../../components/token/tokenAandTokenB";
import TokenBalance from "../../../components/token/tokenBalance";
import { AddressZero } from '@ethersproject/constants'
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import TokenIcon from "../../../components/token/tokenIcon";

const ethers = require('ethers');

function PoolRemove() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { account, library } = useWeb3React()
  const fistFactoryContract = useFistFactoryContract();
  const fistRouterContract = useFistRouterContract();

  // const [loading, setLoading] = useState<boolean>(false);
  // const [loadingState, setLoadingState] = useState<string>("loading")
  // const [loadingText, setLoadingText] = useState<string>("")

  const [value, setValue] = useState<number>(50);

  const [tokenA, setTokenA] = useState<string>(params.tokenA + "");
  const [tokenB, setTokenB] = useState<string>(params.tokenB + "");

  const [tokenADecimals, setTokenADecimals] = useState<string>("0")
  const [tokenBDecimals, setTokenBDecimals] = useState<string>("0")

  const [tokenAReserves, setTokenAReserves] = useState<string>("0")
  const [tokenBReserves, setTokenBReserves] = useState<string>("0")

  const [tokenAtoTokenB, setTokenAtoTokenB] = useState<string>("0")
  const [tokenBtoTokenA, setTokenBtoTokenA] = useState<string>("0")


  const [accountPairAmount, setAccountPairAmount] = useState<string>("0")
  const [pairTotal, setPairTotal] = useState<string>("0")

  const [pairAddress, setPairAddress] = useState<string>("")

  const [pairBalanceAllowance, setPairBalanceAllowance] = useState<string>("0")
  const [approveLoading, setApporveLoading] = useState<boolean>(false);
  const [confirmSend, setConfirmSend] = useState<boolean>(false);
  const [viewNet, setViewnet] = useState<boolean>(false);
  const [viewHash, setViewhash] = useState<string>("");
  const [loadingSend, setLoadingSend] = useState<boolean>(false);

  useEffect(() => {
    init()
  }, [params]);

  const init = () => {
    let tokena = params.tokenA || ""
    let tokenb = params.tokenB || ""

    if (tokena == "BNB") {
      tokena = process.env.REACT_APP_TOKEN_CAKEBNB + ""
    }

    if (tokenb == "BNB") {
      tokenb = process.env.REACT_APP_TOKEN_CAKEBNB + ""
    }

    setTokenA(tokena);
    setTokenB(tokenb);
    getAmountsOut(tokena, tokenb)
    getPair(tokena, tokenb)
  }

  const getPair = async (tokena: string, tokenb: string) => {
    try {
      let pairAddr = await fistFactoryContract?.getPair(tokena, tokenb)
      setPairAddress(pairAddr)
      let pairErc20 = new Contract(pairAddr, ERC20ABI, getProviderOrSigner(library, account || "") as any);
      const allowance: any = await pairErc20?.allowance(account, fistRouterContract?.address);
      setPairBalanceAllowance(allowance.toString())
      let pairContract = new Contract(pairAddr, PairABI, getProviderOrSigner(library, account || "") as any);

      let pairBalance = await pairContract?.balanceOf(account)
      setAccountPairAmount(pairBalance.toString())

      let total = await pairContract?.totalSupply()
      setPairTotal(total.toString())

      let token0 = await pairContract?.token0()
      console.log("token0", token0)

      let reserves = await pairContract?.getReserves()
      console.log("getReserves", reserves)

      if (tokena == token0) {
        setTokenAReserves(reserves._reserve0.toString());
        setTokenBReserves(reserves._reserve1.toString());
      } else {
        setTokenAReserves(reserves._reserve1.toString());
        setTokenBReserves(reserves._reserve0.toString());
      }

    } catch (error) {
      setAccountPairAmount("0");
      setPairTotal("0");
      setTokenAReserves("0");
      setTokenBReserves("0");
    }
  }

  const getAmountsOut = async (tokena: string, tokenb: string) => {
    try {
      let tokenAErc20 = new Contract(tokena, ERC20ABI, getProviderOrSigner(library, account || "") as any);
      let tokenBErc20 = new Contract(tokenb, ERC20ABI, getProviderOrSigner(library, account || "") as any);
      const tokenADataDecimals: any = await tokenAErc20?.decimals()
      setTokenADecimals(tokenADataDecimals)
      const tokenBDataDecimals: any = await tokenBErc20?.decimals()
      setTokenBDecimals(tokenBDataDecimals)
      let info = await fistRouterContract?.getAmountsOut(toTokenValue(1, tokenADataDecimals), [tokena, tokenb])
      console.log("info", info[0].toString(), info[1].toString())
      setTokenAtoTokenB(info[1].toString())
      let info1 = await fistRouterContract?.getAmountsOut(toTokenValue(1, tokenBDataDecimals), [tokenb, tokena])
      console.log("info1", info1[0].toString(), info1[1].toString())

      setTokenBtoTokenA(info1[1].toString())
    } catch (error) {
    }
  }

  const getValue = (value: any) => {
    console.log(value)
    setValue(value);
  };

  const sendRemoveLiquidity = async () => {
    setConfirmSend(false)
    //setLoadingSend(true)
    let deadline = parseInt(new Date().getTime() / 1000 + "") + 120;
    let liquidityAmount = new BigNumber(accountPairAmount).toFixed();

    if (value < 100) {
      liquidityAmount = new BigNumber(liquidityAmount).multipliedBy(value).dividedBy(100).toFixed(0);
    }

    if (tokenA == "BNB" || tokenA == process.env.REACT_APP_TOKEN_CAKEBNB + "") {
      removeLiquidityETH(tokenB, liquidityAmount, 0, 0, account, deadline);
    } else if (tokenB == "BNB" || tokenB == process.env.REACT_APP_TOKEN_CAKEBNB + "") {
      removeLiquidityETH(tokenA, liquidityAmount, 0, 0, account, deadline);
    } else {
      try {
        const gas: any = await fistRouterContract?.estimateGas.removeLiquidity(tokenA, tokenB, liquidityAmount, 0, 0, account, deadline, { from: account })
        const response = await fistRouterContract?.removeLiquidity(tokenA, tokenB, liquidityAmount, 0, 0, account, deadline, { from: account, gasLimit: gas.mul(105).div(100) })
        setViewhash(response.hash)
        let provider = new ethers.providers.Web3Provider(library.provider);
        let receipt = await provider.waitForTransaction(response.hash);
        if (receipt !== null) {
          init()
          setViewnet(true)
          setConfirmSend(false)
        }
      } catch (error) {
        //setLoadingSend(false)
        setConfirmSend(true)
      }
    }
  };

  const removeLiquidityETH = async (token: string, liquidityAmount: any, amountTokenMin: any, amountETHMin: any, to: any, deadline: any) => {

    try {
      const gas: any = await fistRouterContract?.estimateGas.removeLiquidityETH(token, liquidityAmount, amountTokenMin, amountETHMin, to, deadline, { from: account })

      const response = await fistRouterContract?.removeLiquidityETH(token, liquidityAmount, amountTokenMin, amountETHMin, to, deadline, { from: account, gasLimit: gas.mul(105).div(100) })
      setViewhash(response.hash)
      let provider = new ethers.providers.Web3Provider(library.provider);
      let receipt = await provider.waitForTransaction(response.hash);
      if (receipt !== null) {
        init()
        setViewnet(true)
        setConfirmSend(false)
      }
    } catch (error) {
      //setLoadingSend(false)
      setConfirmSend(true)
    }
  }

  const sendApprove = async (token: any, approveAddress: string) => {
    setApporveLoading(true)
    let approveContract = new Contract(token, ERC20ABI, getProviderOrSigner(library, account || "") as any);

    try {
      const gas: any = await approveContract?.estimateGas.approve(approveAddress, MAX_UNIT256, { from: account });
      const response = await approveContract?.approve(approveAddress, MAX_UNIT256, {
        from: account,
        gasLimit: gas.mul(105).div(100)
      });
      let provider = new ethers.providers.Web3Provider(library.provider);

      let receipt = await provider.waitForTransaction(response.hash);
      if (receipt !== null) {
        if (receipt.status && receipt.status == 1) {
          init()
          setTimeout(() => {
            setApporveLoading(false)
          }, 1000);
        } else {
          setTimeout(() => {
            setApporveLoading(false)
          }, 1000);
        }
      }
    } catch (err: any) {
      setTimeout(() => {
        setApporveLoading(false)
      }, 1000);
    }
  }


  return (<>
    <TipPop hash={viewHash} />
    <Modal
      className='LoadingBox'
      open={loadingSend}
      centered
      bodyStyle={{
        borderRadius: "16px"
      }}
      footer={null}
      closeIcon={null}
    >
      <div className='bg LoadingBoxInfo' >
        <div className="flex">
          <div className="flex-1"></div>
          <div>
            <svg onClick={() => {
              //setLoadingSend(false)
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </div>
        </div>

        <div style={{
          margin: "0 auto",
          textAlign: "center",
          padding: "20px 0"
        }}>
          <svg className="rotate" width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92" stroke="#2172E5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div>
          <p className="font18 fontw500 textCenter blackOrwhite">{t("WaitingForConfirmation")}</p>
          <div className="textCenter blackOrwhite" style={{ padding: "10px 0 !importeant" }}>
            {t("Removing")}
            &bnsp;
            {
              fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).multipliedBy(value).dividedBy(100).toFixed(), Number(tokenADecimals), 6)
            }
            &bnsp;
            <TokenName token={tokenA + ""} />
            &bnsp;
            {t("and")}
            &bnsp;
            {
              fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).multipliedBy(value).dividedBy(100).toFixed(), Number(tokenBDecimals), 6)
            }
            &bnsp;
            <TokenName token={tokenB + ""} />
          </div>
          <p className="textCenter textColor">{t("ConfirmWallet")}</p>
        </div>
      </div>
    </Modal>

    <Modal
      className='LoadingBox'
      open={confirmSend}
      centered
      bodyStyle={{
        borderRadius: "16px"
      }}
      footer={null}
      closeIcon={null}
    >
      <div className='bg LoadingBoxInfo' >
        <div className="flex">
          <div className="flex-1">
            <p className=" fontw500  blackOrwhite"> {t("Youwillreceive")}</p>
          </div>
          <div>
            <svg onClick={() => {
              setConfirmSend(false)
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </div>
        </div>

        <div className="flex" style={{
          paddingTop: "10px"
        }}>
          <div className="flex-1 blackOrwhite">
            <p className="blackOrwhite font18 fontw500">
              {
                fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).multipliedBy(value).dividedBy(100).toFixed(), Number(tokenADecimals), 6)
              }
            </p>
          </div>
          <div className="flex blackOrwhite">
            <TokenIcon token={tokenA + ""} />
            <div style={{
              lineHeight: "24px",
              marginLeft: "10px"
            }}>
              <TokenName token={tokenA + ""} />
            </div>
          </div>
        </div>
        <div className="textColor">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#565A69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
        </div>
        <div className="flex" style={{
          paddingBottom: "10px"
        }}>
          <div className="flex-1 blackOrwhite">
            <p className="blackOrwhite font18 fontw500">
              {
                fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).multipliedBy(value).dividedBy(100).toFixed(), Number(tokenBDecimals), 6)
              }
            </p>
          </div>
          <div className="flex blackOrwhite">
            <TokenIcon token={tokenB + ""} />
            <div style={{
              lineHeight: "24px",
              marginLeft: "10px"
            }}>
              <TokenName token={tokenB + ""} />
            </div>
          </div>
        </div>
        <div className="textColor" style={{
          fontSize: "12px"
        }}>
          {t("Youwillreceive1")}
        </div>

        <div className="textColor flex" style={{
          paddingBottom: "5px"
        }}>
          <div className="flex-1">
            FST  <TokenName token={tokenA + ""} />/ <TokenName token={tokenB + ""} />   {t("Burned")}
          </div>
          <div className="flex">
            <TokenAandTokenB tokenA={tokenA + ""} tokenB={tokenB + ""} />
            {
              fromTokenValue(new BigNumber(accountPairAmount).multipliedBy(value).dividedBy(100).toFixed(), 18, 6)
            }
          </div>
        </div>

        <div className="flex blackOrwhite">
          <div className="flex-1">
            {t("Price")}
          </div>
          <div >
            <div style={{
              paddingBottom: "5px"
            }}>
              1 <TokenName token={tokenA + ""} /> ={fromTokenValue(tokenAtoTokenB, Number(tokenBDecimals), 4)}<TokenName token={tokenB + ""} />
            </div>
            <div>
              1 <TokenName token={tokenB + ""} /> ={fromTokenValue(tokenBtoTokenA, Number(tokenADecimals), 4)}<TokenName token={tokenA + ""} />
            </div>
          </div>
        </div>

        <div className="sendBtn" >
          <p onClick={() => {
            sendRemoveLiquidity()
          }}>  {t("Confirm")}</p>
        </div>
      </div>
    </Modal>

    <Modal
      className='LoadingBox'
      open={viewNet}
      centered
      bodyStyle={{
        borderRadius: "16px"
      }}
      footer={null}
      closeIcon={null}
    >
      <div className='bg LoadingBoxInfo' >
        <div className="flex">
          <div className="flex-1"></div>
          <div>
            <svg onClick={() => {
              setViewnet(false)
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="sc-hia0it-1 cinYcx blackOrwhite"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </div>
        </div>

        <div style={{
          margin: "0 auto",
          textAlign: "center",
          padding: "20px 0"
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="90px" viewBox="0 0 24 24" fill="none" stroke="#F3B71E" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg>
        </div>

        <div>
          <p className="font18 fontw500 textCenter">{t("TransactionSubmitted")}</p>
          <p className="fontw500 textCenter" style={{
            fontSize: "12px",
            cursor: "pointer",
            color: "#F3B71E"
          }}
            onClick={() => {
              window.open("https://bscscan.com/tx/" + viewHash, '_blank');
            }}
          >{t("ViewonExplorer")}</p>
        </div>

        <div className="sendBtn" >
          <p onClick={() => {
            setViewnet(false)
            //setLoadingSend(false)
            setConfirmSend(false)
          }}> {t("Close")}</p>
        </div>
      </div>
    </Modal>
    <div className='mainContent'>

      <div className='bg1 poolAddBox  '>
        <div className="poolheader flex ">
          <div onClick={() => {
            navigate('/pool')
          }}>
            <svg className="sc-1tguxka-4 kSTWMO blackOrwhite" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
          </div>
          <div className="flex-1">
            <p className=" fontw500 font18 textCenter blackOrwhite" >{t("removeTitle1")}</p>
          </div>
        </div>
        <div className="messageInfo">
          <p className="mainColor font16">{t("removeTitle2")}
          </p>
        </div>

        <div className=" bg poolRemove borderColor" style={{
          marginTop: "20px"
        }}>
          <div className="blackOrwhite">
            {t("RemoveAmount")}
          </div>

          <div className="blackOrwhite fontw500" style={{
            fontSize: "72px"
          }}>
            {value} %
          </div>
          <div>
            <Slider
              handleStyle={{
                backgroundColor: "#f3b71e",
                borderColor: "#f3b71e",
                width: "20px",
                height: "20px",
                marginTop: "-8px",
              }}
              trackStyle={{
                backgroundColor: "#f3b71e",
              }}
              defaultValue={value}
              value={value}
              tooltip={{ formatter: null }} onChange={(e) => { getValue(e) }} />
          </div>
          <div className="flex textCenter poolRemoveSlider">
            <div className="flex-1">
              <span onClick={() => {
                setValue(25)
              }}>25%</span>
            </div>
            <div className="flex-1">
              <span onClick={() => {
                setValue(50)
              }}>50%</span>
            </div>
            <div className="flex-1">
              <span onClick={() => {
                setValue(75)
              }}>75%</span>
            </div>
            <div className="flex-1">
              <span onClick={() => {
                setValue(100)
              }}>100%</span>
            </div>
          </div>
        </div>

        <div className=" textCenter textColor" style={{
          padding: "10px 0"
        }}>
          <svg className="textColor" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
        </div>

        <div className="bg poolRemoveBox">
          <div className="flex blackOrwhite  fontw500">
            <div className="flex-1 ">
              {
                fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).multipliedBy(value).dividedBy(100).toFixed(), Number(tokenADecimals), 6)
              }
            </div>
            <div>
              <TokenName token={tokenA} />
            </div>
          </div>
          <div className="flex blackOrwhite  fontw500">
            <div className="flex-1 ">
              {
                fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).multipliedBy(value).dividedBy(100).toFixed(), Number(tokenBDecimals), 6)
              }
            </div>
            <div>
              <TokenName token={tokenB} />
            </div>
          </div>
        </div>

        <div className="flex textColor" style={{
          padding: "16px"
        }}>
          <div>
            {t("Price")}:
          </div>
          <div className="flex-1" style={{
            textAlign: "right",
          }}>
            <p>1 <TokenName token={tokenA} /> ={fromTokenValue(tokenAtoTokenB, Number(tokenBDecimals), 6)} <TokenName token={tokenB} /></p>
            <p>1 <TokenName token={tokenB} /> ={fromTokenValue(tokenBtoTokenA, Number(tokenADecimals), 6)} <TokenName token={tokenA} /></p>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1" style={{
            margin: "0px 10px"
          }}>
            {
              new BigNumber(pairBalanceAllowance).isLessThan(new BigNumber(accountPairAmount).multipliedBy(value).dividedBy(100).toFixed(0)) ? <div className="sendBtn " >
                <p onClick={() => {
                  sendApprove(pairAddress, fistRouterContract?.address + "")
                }}>
                  {t("Approve")}
                  {
                    approveLoading ? <svg style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                      position: "relative",
                      top: "2px"
                    }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="white" className="sc-bftyxn-0 ldGXgk rotate"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> : <></>
                  }
                </p>
              </div> : <div className="sendBtnNo bg textColor " >
                <p>{t("Approved")}</p>
              </div>
            }
          </div>
          <div className="flex-1" style={{
            margin: "0px 10px"
          }}>
            {
              new BigNumber(pairBalanceAllowance).isLessThan(new BigNumber(accountPairAmount).multipliedBy(value).dividedBy(100).toFixed(0)) ? <div className="sendBtnNo bg textColor " >
                <p style={{
                  margin: "0px 10px"
                }} > {t("remove")} </p>
              </div> : <div className="sendBtn " >
                <p style={{
                  margin: "0px 10px"
                }} onClick={() => {
                  setConfirmSend(true)
                }}> {t("remove")} </p>
              </div>
            }
          </div>
        </div>
      </div>
      {
        pairAddress !== AddressZero ? <div className="poolDetailBox bg2">
          <div>
            <p className="blackOrwhite font16"> {t("YourPosition")}</p>
          </div>
          <div className="flex blackOrwhite " style={{
            padding: "8px 0px"
          }}>
            <div className="flex-1">
              <div className="flex font18">
                <TokenAandTokenB tokenA={tokenA} tokenB={tokenB} />
                <TokenName token={tokenA} />
                /
                <TokenName token={tokenB} />
              </div>
            </div>
            <div className="font18"><TokenBalance token={pairAddress} addr={account + ""} decimalPlaces={6} /></div>
          </div>
          <div className="flex blackOrwhite font16">
            <div className="flex-1">{t("YourPoolShare")}:</div>
            <div>
              {accountPairAmount == "0" ? 0 : <>
                {
                  new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01) ? "<0.01" : new BigNumber(accountPairAmount).multipliedBy(100).dividedBy(pairTotal).isLessThan(0.01)
                }
              </>}%
            </div>
          </div>
          <div className="flex blackOrwhite font16">
            <div className="flex-1"><TokenName token={tokenA} />:</div>
            <div>
              {
                fromTokenValue(new BigNumber(tokenAReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenADecimals), 3)
              }
            </div>
          </div>
          <div className="flex blackOrwhite font16">
            <div className="flex-1"><TokenName token={tokenB} />:</div>
            <div>
              {
                fromTokenValue(new BigNumber(tokenBReserves).multipliedBy(accountPairAmount).dividedBy(pairTotal).toFixed(), Number(tokenBDecimals), 3)
              }
            </div>
          </div>
        </div> : <></>
      }
    </div>
    <div className="footer bg1 borderColor ">
      <FooterNav />
    </div>
  </>
  )
}

export default PoolRemove