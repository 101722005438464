import { useEffect, useState } from "react";
import FooterNav from "../../components/footerNav/footerNav"
import './pool.css'
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import PairItem from "./pairItem";
import { pairData } from "../../constants";
import { removeDup } from "../../utils";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../components/changeLanguage/changeLanguage";
const FIST = process.env.REACT_APP_TOKEN_FIST + "";
const USDT = process.env.REACT_APP_TOKEN_USDT + "";

function Pool() {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const { account } = useWeb3React()

    const [pairList, setPairList] = useState<any>([])

    useEffect(() => {
        getPaird()
    }, [account])

    const getPaird = async () => {
        let localPairData = localStorage.getItem("localPairData") + "";
        let arr = pairData
        if (localPairData) {
            var newArr = removeDup(arr.concat(JSON.parse(localPairData)));
            console.log(newArr)
            setPairList(newArr)
        } else {
            setPairList(arr)
        }
    }

    return (<>
        <div className='mainContent'>
            <div className='bg1 poolBox '>
                <div className="poolBoxTitle">
                    <p className="blackOrwhite">{t("poolTitle")}</p>
                </div>
                <div className="poolBoxDetail">
                    <p className="blackOrwhite">
                        {t("poolTitle1")}
                    </p>
                </div>

                <div className="flex poolBtn">
                    <div className="flex-1">
                        <p style={{
                            color: "#ffffff",
                            background: "#f3b71e"
                        }}
                            onClick={() => {
                                navigate('/pool/addPair');
                            }}
                        >
                            {t("ImportPool")}
                        </p>
                    </div>
                    <div className=" flex-1">
                        <p className="bg1"
                            onClick={() => {
                                navigate('/pool/add/' + USDT + '/' + FIST);
                            }}
                            style={{
                                color: "#f3b71e"
                            }}>
                            {t("CreateaPair")}
                        </p>
                    </div>
                </div>

                <div className="pairList">
                    {
                        pairList.length > 0 && pairList.map((item: any, index: number) => {
                            return <PairItem pairaddr={item} key={index} />
                        })
                    }
                </div>
            </div>

            <div style={{
                paddingTop:"20px"
            }}>
                <ChangeLanguage />
            </div>
        </div>
        <div className="footer bg1 borderColor ">
            <FooterNav />
        </div>
    </>)
}

export default Pool